import React, { ChangeEvent, useCallback, useEffect, useState } from "react"
import { MenuItem, styled, TextField } from "@mui/material"
import { selectInputVariant } from "~/store/global/globalSlice"
import { useAppSelector } from "~/store/hooks"
import {
    CreateCustomFieldObjectPayload,
    CustomFieldValueI,
} from "~/domains/transactions/custom-fields/types/CustomFieldValue"
import { useSaveBuffer } from "~/hooks"
import {
    useCreateCustomField,
    useSavePendingCustomFieldObject,
    useUpdateCustomFieldObject,
} from "~/domains/transactions/custom-fields/hooks"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import { defineMessage, MessageDescriptor, useIntl } from "react-intl"
import "./CustomFieldObjectAsTextfield.scss"

const noneMessage = defineMessage({
    id: "customfields.company.none",
    defaultMessage: "None",
})

const CustomFieldContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
})

const CustomFieldLabel = styled("div")({
    color: "var(--color-light-grey)",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
})

const CustomFieldValue = styled("div")({
    color: "var(--color-black)",
})

interface Props<T extends Record<keyof T, MessageDescriptor>> {
    customField: CustomFieldValueI
    label: string
    enum?: T
    isTemporaryId?: boolean
    isReadOnly?: boolean
    isRequired?: boolean
    className?: string
}

export const CustomFieldObjectAsTextfield = <T extends Record<keyof T, MessageDescriptor>>({
    customField,
    label,
    enum: enumOptions,
    isTemporaryId = false,
    isReadOnly = false,
    isRequired = false,
    className,
}: Props<T>) => {
    const [inputValue, setInputValue] = useState<string>("")

    const {
        id: customFieldId,
        name: customFieldName,
        objectId,
        value,
        contextId,
        contextType,
        organizationId,
    } = customField

    const { formatMessage } = useIntl()
    const inputVariant = useAppSelector(selectInputVariant)
    const createCustomField = useCreateCustomField()
    const updateCustomFieldObject = useUpdateCustomFieldObject(objectId, customField)
    const savePendingCustomFieldObject = useSavePendingCustomFieldObject(customField)

    useEffect(() => {
        if (value) {
            setInputValue(value)
        }
    }, [value])

    const save = useCallback(() => {
        const createCustomFieldObjectPayload: CreateCustomFieldObjectPayload = {
            name: customFieldName,
            value: inputValue,
            objectId,
            contextId,
            contextType: contextType ? CustomFieldObjectType[contextType] : undefined,
            organizationId,
        }
        if (contextId && !isTemporaryId) {
            if (!value) {
                createCustomField(createCustomFieldObjectPayload, true)
            } else {
                updateCustomFieldObject(customFieldId, inputValue)
            }
        } else {
            savePendingCustomFieldObject(createCustomFieldObjectPayload)
        }
    }, [
        createCustomField,
        updateCustomFieldObject,
        savePendingCustomFieldObject,
        inputValue,
        objectId,
        customFieldName,
        contextId,
        contextType,
        isTemporaryId,
        organizationId,
        value,
        customFieldId,
    ])

    const { setHasChanges } = useSaveBuffer(
        save,
        contextId && !isTemporaryId ? undefined : { savingTime: 0, displaySavedTime: 0 }
    )

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value
            setInputValue(newValue)
            setHasChanges(true)
        },
        [setInputValue, setHasChanges]
    )

    const textfieldConfig = {
        id: `customfield-${customFieldId}-textfield`,
        name: `customfield-${customFieldName}-textfield`,
        variant: inputVariant,
        label,
        value: inputValue,
        onChange,
        required: isRequired,
        fullWidth: true,
        className,
    }

    const renderEnumOptionItems = useCallback(() => {
        if (enumOptions) {
            return Object.entries(enumOptions).map(([key]) => (
                <MenuItem key={key} value={key}>
                    {formatMessage(enumOptions[key])}
                </MenuItem>
            ))
        }
    }, [enumOptions])

    const getCustomFieldValue = (): string => {
        return enumOptions && inputValue
            ? formatMessage(enumOptions[inputValue])
            : inputValue ?? formatMessage(noneMessage)
    }

    if (isReadOnly && !inputValue) {
        return
    }

    if (isReadOnly) {
        return (
            <CustomFieldContainer>
                <CustomFieldLabel>{label}</CustomFieldLabel>
                <CustomFieldValue>{getCustomFieldValue()}</CustomFieldValue>
            </CustomFieldContainer>
        )
    }

    return (
        <>
            {enumOptions ? (
                <TextField
                    {...textfieldConfig}
                    select
                    SelectProps={{
                        MenuProps: {
                            id: "enum-options-menu",
                        },
                    }}
                >
                    {renderEnumOptionItems()}
                </TextField>
            ) : (
                <TextField {...textfieldConfig} />
            )}
        </>
    )
}
