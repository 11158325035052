import * as t from "io-ts"
import { optional } from "~/types/utils"
import { genericParser } from "~/utils"
import { FolderSummaryI, FolderSummaryIO, parseFolderSummary } from "./Folder"
import { getResultSuccessValue, isResultSuccess, ResultSuccess } from "~/core/Result"

export const DocumentIO = t.intersection([
    t.type({
        id: t.string,
        maxVersions: t.number,
        createdAt: t.string,
        updatedAt: t.string,
    }),
    t.partial({
        latestFileVersion: optional(t.string),
        description: optional(t.string),
        documentType: optional(t.string),
        name: optional(t.string),
    }),
])

export const PaginatedDocumentsResultIO = t.type({
    documents: t.array(DocumentIO),
    folders: t.array(FolderSummaryIO),
    page: t.number,
    page_size: t.number,
    total_documents: t.number,
    total_pages: t.number,
})

export type DocumentI = t.TypeOf<typeof DocumentIO>
export type PaginatedDocumentsResultI = t.TypeOf<typeof PaginatedDocumentsResultIO> & {
    folders: FolderSummaryI[]
    documents: DocumentI[]
}

export const parseDocument = (data: unknown) => {
    return genericParser<DocumentI>(data, DocumentIO)
}

export const parseDocumentsResults = (data: unknown) => {
    const result = genericParser(data, PaginatedDocumentsResultIO)
    if (isResultSuccess(result)) {
        return ResultSuccess<PaginatedDocumentsResultI>({
            ...result.result,
            documents: result.result.documents.map(parseDocument).filter(isResultSuccess).map(getResultSuccessValue),
            folders: result.result.folders.map(parseFolderSummary).filter(isResultSuccess).map(getResultSuccessValue),
        })
    }
    return result
}
