import { OrganizationId } from "~/types"
import { CustomFieldObjectType } from "../../types/CustomFieldObjectType"
import { CreateCustomFieldObjectByNameRecord, CreateCustomFieldObjectPayload, CustomFieldValueI } from "../../types/CustomFieldValue"

export const createContextIdFromObjectAndOrgIds = (
    objectId: string,
    objectType: string,
    organizationId: OrganizationId | undefined
) => `${objectType}:${objectId}:${organizationId}`

const createCustomFieldTemplate = (
    id: string,
    objectId: string,
    objectType: string,
    currentOrganizationId: string | undefined
) => ({
    id,
    name: id,
    value: "",
    objectId,
    contextId: createContextIdFromObjectAndOrgIds(objectId, objectType, currentOrganizationId),
    contextType: CustomFieldObjectType.PURCHASE_ORDER,
    organizationId: currentOrganizationId,
})

export function getOrCreateCustomField(
    customFields: CustomFieldValueI[] | null,
    fieldName: string,
    objectId: string,
    objectType: string,
    currentOrganizationId: string | undefined
): CustomFieldValueI {
    if (!customFields) {
        return createCustomFieldTemplate(fieldName, objectId, objectType, currentOrganizationId)
    }
    const foundCustomField = customFields.find((cf) => cf.name === fieldName)
    return foundCustomField ?? createCustomFieldTemplate(fieldName, objectId, objectType, currentOrganizationId)
}

export const convertToPayloadArray = (
    pendingCustomFieldObjects: CreateCustomFieldObjectByNameRecord,
    purchaseRequestId: string
): CreateCustomFieldObjectPayload[] => {
    const customFieldsObjectsToCreate = Object.values(pendingCustomFieldObjects).flat()

    return Object.values(customFieldsObjectsToCreate).map((customField) => ({
        ...customField,
        objectId: purchaseRequestId,
        contextId: createContextIdFromObjectAndOrgIds(
            purchaseRequestId,
            CustomFieldObjectType.PURCHASE_REQUEST,
            customField.organizationId
        ),
    }))
}
