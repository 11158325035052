import { useCallback, useEffect, useMemo } from "react"
import { useTagsApi } from "../tagsApi"
import { OrganizationId } from "~/types"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { selectTags } from "../store/tagsSelector"
import { tagsActions } from "../store/tagsSlice"
import { useIntl } from "react-intl"
import { commonMessages } from "~/common-messages"
import { store } from "~/store"

export const useOrganizationTagGroups = (organizationId: OrganizationId | undefined) => {
    const { formatMessage } = useIntl()
    const apiClient = useTagsApi()
    const tagsState = useAppSelector(selectTags)
    const dispatch = useAppDispatch()

    const tagGroups = tagsState.tagsForOrganizationId === organizationId ? tagsState.tagGroups : null
    const loading = tagsState.loadingTagGroups
    const error = tagsState.tagsForOrganizationId === organizationId ? tagsState.errorLoadingTagGroups : undefined

    const fetchOrganizationTagGroups = useCallback(
        (forceRefetch: boolean = false) => {
            // we use sync tag state because if this hook is used in multiple different component, the tag could be fetch multiple times at the same time
            const syncTagState = selectTags(store.getState())
            if (
                organizationId &&
                !syncTagState.loadingTagGroups &&
                (forceRefetch || organizationId !== syncTagState.tagsForOrganizationId)
            ) {
                dispatch(tagsActions.fetchOrganizationTagGroups({ organizationId, loading: !forceRefetch }))
                apiClient
                    .getTagGroups(organizationId)
                    .then((tagGroups) => {
                        dispatch(tagsActions.fetchOrganizationTagGroupsSuccess({ organizationId, tagGroups }))
                    })
                    .catch(() => {
                        dispatch(
                            tagsActions.fetchOrganizationTagGroupsFailed({
                                organizationId,
                                error: formatMessage(commonMessages.error),
                            })
                        )
                    })
            }
        },
        [organizationId, tagsState, dispatch]
    )

    useEffect(() => {
        fetchOrganizationTagGroups()
    }, [organizationId])

    return useMemo(
        () => ({ tagGroups, loading, error, refetchTagGroups: fetchOrganizationTagGroups }),
        [tagGroups, loading, error, fetchOrganizationTagGroups]
    )
}
