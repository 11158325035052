import React, { useCallback, useEffect, useState } from "react"
import "./Sidebar.scss"
import {
    ChevronsLeft,
    ChevronsRight,
    FileText,
    Folder,
    LogIn,
    Power,
    User,
    Users,
    Play,
    DollarSign,
    Send,
    ShoppingBag,
    Command,
    X as CloseMenu,
    Mail,
} from "react-feather"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import classnames from "classnames"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { globalActions, selectDisplayMenu, selectKeepSidebarOpened } from "~/store/global/globalSlice"
import { useSegment } from "~/hooks/useSegment"
import { authActions, selectIsConnected } from "~/store/auth/authSlice"
import { SelectOrganization } from "~/features/account/components/Organization/SelectOrganization"
import { SideBarCompanyLogo } from "../CompanyLogo"
import { PlusIcon } from "../Icons"
import { Link, generatePath, useLocation } from "react-router-dom"
import { PURCHASE_REQUESTS_ROUTE } from "~/domains/transactions/purchase-requests/routes"
import { PURCHASE_ORDERS_ROUTE, PURCHASE_ORDERS_ROUTE_DEFAULT } from "~/domains/transactions/purchase-orders/routes"
import { ViewTypeI, AuthorizationName } from "~/types"
import { LangSelector } from "~/components/LangSelector"
import { HOME_ROUTE } from "~/core/routes"
import { MY_DOCUMENTS_ROUTE, PROFILE_ROUTE } from "~/features/account/routes"
import { WORKFLOWS_LIST } from "~/features/workflow/routes"
import { FLOWS_LIST } from "~/domains/orchestration/flows/routes"
import { PARTNERS_ROUTE } from "~/domains/transactions/book-of-relations/routes"
import { BUDGETS_LIST } from "~/features/budget/routes"
import { COPILOT_PROMPT_ROUTE } from "~/domains/copilot/prompt/routes"
import { Features, isFeatureEnabled } from "~/core/featureFlag"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { useHasPermissions } from "~/domains/identity/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { Tooltip } from "@mui/material"
import classNames from "classnames"
import { EMAILS_INBOX } from "~/domains/transactions/emails/routes"
import { permissionMessages } from "~/domains/identity/features/roles-permissions/utils/permissions"
import { DOCUMENTS_ROUTE } from "~/domains/identity/features/documents/routes"

const ICON_SIZE = 18

const messages = defineMessages({
    newUpload: { id: "navbar.newUpload", defaultMessage: "New upload" },
    invoices: { id: "navbar.invoices", defaultMessage: "Invoices" },
    documents: { id: "navbar.documents", defaultMessage: "Documents" },
    partners: { id: "navbar.partners", defaultMessage: "Book of relations" },
    budgets: { id: "navbar.budgets", defaultMessage: "Budgets" },
    purchaseOrders: { id: "navbar.purchases.orders", defaultMessage: "Payments" },
    purchaseRequests: { id: "navbar.purchases.requests", defaultMessage: "Payments" },
    inbox: { id: "navbar.inbox", defaultMessage: "Inbox" },
    account: { id: "navbar.account", defaultMessage: "My account" },
    logout: { id: "navbar.logout", defaultMessage: "Logout" },
    login: { id: "navbar.login", defaultMessage: "Login" },
    workflow: { id: "navbar.workflow", defaultMessage: "Workflows" },
    flows: { id: "navbar.flows", defaultMessage: "Flows (beta)" },
    copilot: { id: "navbar.copilot", defaultMessage: "Copilot" },
})

export default function Sidebar() {
    const { formatMessage } = useIntl()
    const [open, setOpen] = useState<boolean>(false)
    const [displayLang, setDisplayLang] = useState<boolean>(false)
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const isConnected = useAppSelector(selectIsConnected)
    const keepOpen = useAppSelector(selectKeepSidebarOpened)
    const displayMenu = useAppSelector(selectDisplayMenu)
    const sidebarClasses = classnames("sidebar", {
        ["open"]: displayMenu,
        ["keep-open"]: keepOpen || displayMenu,
    })
    const { segmentLogout } = useSegment()
    const dispatch = useAppDispatch()
    const location = useLocation()

    const areFlowsEnabled = isFeatureEnabled(Features.Flows, currentOrganizationId)

    const { hasPermission: hasBudgetsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BUDGETS
    )
    const { hasPermission: hasWorkflowsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.ORCHESTRATION,
        ScopeName.WORKFLOWS
    )

    const { hasPermission: hasPOsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )
    const { hasPermission: hasInvoicesPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.INVOICES
    )
    const areDocumentsEnabled = isFeatureEnabled(Features.Documents, currentOrganizationId)

    const { hasPermission: hasBookOfRelationsPermission } = useHasPermissions(
        currentOrganizationId ?? "",
        AuthorizationName.READ,
        DomainName.TRANSACTION,
        ScopeName.BOOK_OF_RELATIONS
    )

    const handleLogout = () => {
        segmentLogout()
        dispatch(authActions.logout())
    }

    const toggleKeepSidebarOpened = useCallback(() => {
        dispatch(globalActions.toggleKeepSidebarOpened())
    }, [dispatch])

    const handleCloseNav = useCallback(() => {
        setDisplayLang(false)
        setOpen(false)
    }, [setDisplayLang, setOpen])

    useEffect(() => {
        const updateDimension = () => {
            setScreenWidth(window.innerWidth)
        }
        window.addEventListener("resize", updateDimension)

        return () => {
            window.removeEventListener("resize", updateDimension)
        }
    }, [setScreenWidth])

    const handleDisplayHamburgerMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        event.stopPropagation()
        dispatch(globalActions.setDisplayMenu())
    }

    const handleLogin = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault()
            dispatch(authActions.openPopupSignIn())
        },
        [dispatch]
    )

    const renderInboxLink = useCallback(() => {
        const inboxClasses = classNames("", {
            active: location.pathname.startsWith("/emails/"),
            disabled: !hasInvoicesPermission,
        })
        return (
            <li className={inboxClasses}>
                <Link to={hasInvoicesPermission ? generatePath(EMAILS_INBOX) : "#"}>
                    <Mail size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.inbox} />
                    </span>
                </Link>
            </li>
        )
    }, [location, EMAILS_INBOX, ICON_SIZE, formatMessage, hasInvoicesPermission])

    const renderBudgetsLink = useCallback(() => {
        const classes = classNames("", {
            active: location.pathname.startsWith("/budgets"),
            disabled: !hasBudgetsPermission,
        })
        return (
            <li className={classes}>
                <Link to={hasBudgetsPermission ? BUDGETS_LIST : "#"}>
                    <DollarSign size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.budgets} />
                    </span>
                </Link>
            </li>
        )
    }, [location, BUDGETS_LIST, ICON_SIZE, formatMessage, hasBudgetsPermission])

    const renderWorkflowsLink = useCallback(() => {
        const classes = classNames("", {
            active: location.pathname.startsWith("/workflows"),
            disabled: !hasWorkflowsPermission,
        })
        return (
            <li className={classes}>
                <Link to={hasWorkflowsPermission ? WORKFLOWS_LIST : "#"}>
                    <Play size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.workflow} />
                    </span>
                </Link>
            </li>
        )
    }, [location, WORKFLOWS_LIST, ICON_SIZE, formatMessage, hasWorkflowsPermission])

    const renderFlowsLink = useCallback(() => {
        const classes = classNames("", {
            active: location.pathname.startsWith("/flows"),
            disabled: !hasWorkflowsPermission,
        })
        return (
            <li className={classes}>
                <Link to={hasWorkflowsPermission ? FLOWS_LIST : "#"}>
                    <Play size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.flows} />
                    </span>
                </Link>
            </li>
        )
    }, [location, FLOWS_LIST, ICON_SIZE, formatMessage, hasWorkflowsPermission])

    const renderPOsLink = useCallback(() => {
        const classes = classNames("", {
            active: location.pathname.startsWith(PURCHASE_ORDERS_ROUTE_DEFAULT),
            disabled: !hasPOsPermission,
        })
        return (
            <li className={classes}>
                <Link to={hasPOsPermission ? generatePath(PURCHASE_ORDERS_ROUTE, { viewType: ViewTypeI.buyer }) : "#"}>
                    <ShoppingBag size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.purchaseOrders} />
                    </span>
                </Link>
            </li>
        )
    }, [location, PURCHASE_ORDERS_ROUTE, PURCHASE_ORDERS_ROUTE_DEFAULT, ICON_SIZE, formatMessage, hasPOsPermission])

    const renderPRsLink = useCallback(() => {
        const classes = classNames("", {
            active: location.pathname.startsWith(PURCHASE_REQUESTS_ROUTE),
            disabled: !isConnected || !currentOrganizationId,
        })
        return (
            <li className={classes}>
                <Link to={isConnected ? PURCHASE_REQUESTS_ROUTE : "#"}>
                    <Send size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.purchaseRequests} />
                    </span>
                </Link>
            </li>
        )
    }, [location, PURCHASE_REQUESTS_ROUTE, ICON_SIZE, formatMessage, isConnected, currentOrganizationId])

    const renderInvoicesLink = useCallback(() => {
        const classes = classNames("", {
            active: location.pathname.startsWith("/account/documents/"),
            disabled: !hasInvoicesPermission,
        })
        return (
            <li className={classes}>
                <Link to={hasInvoicesPermission ? generatePath(MY_DOCUMENTS_ROUTE, { view: ViewTypeI.buyer }) : "#"}>
                    <FileText size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.invoices} />
                    </span>
                </Link>
            </li>
        )
    }, [location, MY_DOCUMENTS_ROUTE, ICON_SIZE, formatMessage, hasInvoicesPermission])

    const renderDocumentsLink = useCallback(() => {
        const classes = classNames("", {
            active: location.pathname.startsWith("/documents/"),
        })
        return (
            <li className={classes}>
                <Link to={generatePath(DOCUMENTS_ROUTE)}>
                    <Folder size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.documents} />
                    </span>
                </Link>
            </li>
        )
    }, [])

    const renderBookOfRelationsLink = useCallback(() => {
        const classes = classNames("", {
            active: location.pathname.startsWith("/partners/"),
            disabled: !hasBookOfRelationsPermission,
        })
        return (
            <li className={classes}>
                <Link
                    to={hasBookOfRelationsPermission ? generatePath(PARTNERS_ROUTE, { view: ViewTypeI.supplier }) : "#"}
                >
                    <Users size={ICON_SIZE} />
                    <span>
                        <FormattedMessage {...messages.partners} />
                    </span>
                </Link>
            </li>
        )
    }, [location, PARTNERS_ROUTE, ICON_SIZE, formatMessage, hasBookOfRelationsPermission])

    const isMobile = screenWidth <= 900
    const isOpen = open || isMobile || keepOpen

    const onMouseEnter = useCallback(() => !isMobile && setOpen(true), [setOpen, isMobile])

    const Chevrons = keepOpen ? ChevronsLeft : ChevronsRight

    return (
        <nav className={sidebarClasses} onMouseLeave={handleCloseNav} onMouseEnter={onMouseEnter}>
            <figure className={`sidebar-logo-container${isOpen ? " open" : ""}`}>
                <SideBarCompanyLogo iconOnly={!isOpen} />
                {isMobile ? (
                    <a href="#" title="" className="hamburger-menu" onClick={handleDisplayHamburgerMenu}>
                        <CloseMenu size={30} />
                    </a>
                ) : (
                    <Chevrons
                        size={16}
                        color="#909399"
                        className="sidebar-keep-open"
                        onClick={toggleKeepSidebarOpened}
                    />
                )}
            </figure>
            <ul className="sidebar-top-actions">
                <li className="sidebar-upload">
                    <Link to={HOME_ROUTE} title="">
                        <PlusIcon size={ICON_SIZE} color="white" />
                        <div className="sidebar-upload-label">
                            <FormattedMessage {...messages.newUpload} />
                        </div>
                    </Link>
                </li>
            </ul>
            <hr />
            <ul>
                {hasInvoicesPermission ? (
                    renderInboxLink()
                ) : (
                    <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                        {renderInboxLink()}
                    </Tooltip>
                )}
                <hr />
                {isConnected ? (
                    renderPRsLink()
                ) : (
                    <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                        {renderPRsLink()}
                    </Tooltip>
                )}
                {hasPOsPermission ? (
                    renderPOsLink()
                ) : (
                    <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                        {renderPOsLink()}
                    </Tooltip>
                )}
                {hasInvoicesPermission ? (
                    <>{renderInvoicesLink()}</>
                ) : (
                    <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                        {renderInvoicesLink()}
                    </Tooltip>
                )}
                {areDocumentsEnabled && renderDocumentsLink()}
                <hr />
                {hasWorkflowsPermission ? (
                    renderWorkflowsLink()
                ) : (
                    <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                        {renderWorkflowsLink()}
                    </Tooltip>
                )}

                {/* TEMP: New version of Flows are behind a feature flag */}
                {areFlowsEnabled &&
                    (hasWorkflowsPermission ? (
                        renderFlowsLink()
                    ) : (
                        <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                            {renderFlowsLink()}
                        </Tooltip>
                    ))}

                {hasBookOfRelationsPermission ? (
                    renderBookOfRelationsLink()
                ) : (
                    <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                        {renderBookOfRelationsLink()}
                    </Tooltip>
                )}
                {hasBudgetsPermission ? (
                    renderBudgetsLink()
                ) : (
                    <Tooltip title={formatMessage(permissionMessages.errorNoAccessAdministrator)}>
                        {renderBudgetsLink()}
                    </Tooltip>
                )}
                {isFeatureEnabled(Features.ChatBot, currentOrganizationId) && (
                    <>
                        <hr />
                        <li className={location.pathname.startsWith("/copilot") ? "active" : ""}>
                            <Link to={COPILOT_PROMPT_ROUTE}>
                                <Command size={ICON_SIZE} />
                                <span>
                                    <FormattedMessage {...messages.copilot} />
                                </span>
                            </Link>
                        </li>
                    </>
                )}
            </ul>

            <ul className="nav-profile">
                <SelectOrganization as="li" forceClose={!isOpen} withEditButton />
                <hr />
                <li className={"lang-selector-container"}>
                    <LangSelector displayLang={displayLang} setDisplayLang={setDisplayLang} />
                </li>
                <li>
                    <Link to={PROFILE_ROUTE}>
                        <User size={ICON_SIZE} />
                        <span>
                            <FormattedMessage {...messages.account} />
                        </span>
                    </Link>
                </li>
            </ul>
            <hr />
            {isConnected ? (
                <ul className="nav-logout">
                    <li>
                        <a href="#" title="" onClick={handleLogout}>
                            <Power size={ICON_SIZE} />
                            <span>
                                <FormattedMessage {...messages.logout} />
                            </span>
                        </a>
                    </li>
                </ul>
            ) : (
                <ul>
                    <li>
                        <a href="#" title={formatMessage(messages.login)} onClick={handleLogin}>
                            <LogIn size={ICON_SIZE} />
                            <span>
                                <FormattedMessage {...messages.login} />
                            </span>
                        </a>
                    </li>
                </ul>
            )}
        </nav>
    )
}
