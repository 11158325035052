import React, { useEffect } from "react"
import { styled } from "@mui/system"

import { defineMessages, useIntl } from "react-intl"

import { Loader, useTitle } from "~/core"
import { useNavigate, useParams } from "react-router-dom"
import { useAppSelector } from "~/store/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { useCurrentOrganization } from "~/store/organization/hooks"
import { isResultError, Result } from "~/core/Result"
import { ErrorMessage } from "~/components/ErrorMessage"
import { FLOWS_LIST } from "../routes"
import { ApiFlowsId } from "../types"

const messages = defineMessages({
    title: {
        id: "flows.title",
        defaultMessage: "Flows",
    },
    errorLoadingWorkflow: {
        id: "flows.errorLoadingWorkflow",
        defaultMessage: "Unable to load this flow",
    },
})

export const Editor: React.FC = () => {
    const { formatMessage } = useIntl()

    const { workflowId } = useParams<{ workflowId: ApiFlowsId }>()
    const user = useAppSelector(selectUser)
    const currentOrganization = useCurrentOrganization(user.organizations, true)
    const navigate = useNavigate()

    const pageName = formatMessage(messages.title)

    // TEMP: Replace with useFlow hook
    const error = false
    const loading = false
    const flows = {}
    const flowsResult = {
        success: true,
        result: {},
    } as Result<any>

    useTitle(pageName)

    const StyledLoader = styled("div")({
        height: "calc(100vh - 24px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    })

    useEffect(() => {
        if (error) {
            navigate(FLOWS_LIST, { replace: true })
        }
    }, [error, navigate])

    if (!flows || !currentOrganization || loading) {
        if (error) {
            return <ErrorMessage>{formatMessage(messages.errorLoadingWorkflow)}</ErrorMessage>
        }

        // TEMP: Replace with error component
        if (flowsResult && isResultError(flowsResult)) {
            const error = flowsResult.error
            return <div>TEMP : {error}</div>
        }
        return (
            <StyledLoader>
                <Loader />
            </StyledLoader>
        )
    }

    return <div>{workflowId}</div>
}
