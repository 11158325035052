import React, { useMemo, useCallback, useRef } from "react"
import classnames from "classnames"
import { Check, ChevronRight, FileText, Package, Send, Edit3, Edit, Truck, UserCheck } from "react-feather"
import { defineMessages, useIntl } from "react-intl"
import { Grid, Tooltip } from "@mui/material"
import {
    PurchaseOrderApprovalStatus,
    PurchaseOrderProgressStatus,
    PurchaseOrdersResponse,
    PurchaseOrderStatus,
    PurchaseOrderStatusOrder,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { ViewTypeI } from "~/types"
import { useResizeObserver } from "~/hooks"
import { useAppSelector } from "~/store/hooks"
import { selectPurchaseOrderRelationsState } from "~/domains/transactions/document-relations/store/documentRelationsSlice"
import { allLinesApproved } from "~/domains/transactions/purchase-orders/core/purchaseOrder"
import { FulfillmentStatus, FulfillmentStatusOrder } from "~/domains/transactions/types/Purchases"

import "~/components/Steps/Steps.scss"

enum ApprovalStatusType {
    BUYER_APPROVAL = "buyerApprovalStatus",
    SUPPLIER_APPROVAL = "supplierApprovalStatus",
}

enum FulfillmentStatusType {
    BUYER_FULFILLMENT = "buyerFulfillment",
    SUPPLIER_FULFILLMENT = "supplierFulfillment",
}

enum PurchaseOrderExtraProgressStatus {
    INVOICED = "INVOICED",
}

type PurchaseOrderProgressStatusExtended = PurchaseOrderProgressStatus | PurchaseOrderExtraProgressStatus

const StepperSizes = {
    BuyerSmall: 600,
    SupplierSmall: 480,
    BuyerMedium: 1200,
    SupplierMedium: 920,
    DraftLarge: 1315,
    DraftMedium: 1062,
}

export interface StepsI {
    key: string
    name: string
    status: string
    icon: React.ReactNode
    progress?: PurchaseOrderProgressStatusExtended
}

const listItemStyles = { padding: "8px 12px" }

const stepDataStyles = { gap: "0" }

const separatorStyles = { top: "auto" }

const labelStyles = { display: "none" }

const messages = defineMessages({
    approved: {
        id: "purchase.orders.order.steps.approved",
        defaultMessage: "Approved",
    },
    PODraft: {
        id: "purchase.orders.order.steps.PODraft",
        defaultMessage: "Draft",
    },
    POSubmitted: {
        id: "purchase.orders.order.steps.POSubmitted",
        defaultMessage: "Submitted",
    },
    POShared: {
        id: "purchase.orders.order.steps.POShared",
        defaultMessage: "Shared",
    },
    mutuallyAccepted: {
        id: "purchase.orders.order.steps.mutuallyAccepted",
        defaultMessage: "Accepted",
    },
    inPreparation: {
        id: "purchase.orders.order.steps.inPreparation",
        defaultMessage: "In preparation",
    },
    inDelivery: {
        id: "purchase.orders.order.steps.isTransit",
        defaultMessage: "In transit",
    },
    delivered: {
        id: "purchase.orders.order.steps.delivered",
        defaultMessage: "Delivered",
    },
    received: {
        id: "purchase.orders.order.steps.received",
        defaultMessage: "Received",
    },
    invoiced: {
        id: "purchase.orders.order.steps.invoiced",
        defaultMessage: "Invoiced",
    },
    items: {
        id: "purchase.orders.order.steps.items",
        defaultMessage: "{current}/{total} items",
    },
})

interface StepsProps {
    PO: PurchaseOrdersResponse
    disabled?: boolean
    viewType: ViewTypeI
}

export function StepsPurchaseOrder({ PO, disabled, viewType }: StepsProps) {
    const { formatMessage } = useIntl()
    const listContainerRef = useRef(null)
    const { width } = useResizeObserver(listContainerRef)
    const isBuyerView = viewType === ViewTypeI.buyer

    const isDraft = PO.status === PurchaseOrderStatus.DRAFT
    const isMediumSizedDraft = isDraft && width < StepperSizes.DraftMedium
    const isLargeSizedDraft = isDraft && width < StepperSizes.DraftLarge
    const isMediumContent =
        (isBuyerView && width < StepperSizes.BuyerMedium) || (!isBuyerView && width < StepperSizes.SupplierMedium)
    const isSmallContent =
        (isBuyerView && width < StepperSizes.BuyerSmall) || (!isBuyerView && width < StepperSizes.SupplierSmall)
    const isMediumDraftOrContent = isMediumSizedDraft || (!isDraft && isMediumContent)

    const { purchaseOrderRelations } = useAppSelector(selectPurchaseOrderRelationsState)
    const areAllLinesApproved = useMemo(() => allLinesApproved(PO.lines, viewType), [PO.lines, viewType])

    const classes = classnames("steps po-steps", {
        disabled,
    })

    const getNumberOfLinesByProgress = useCallback(
        (progress: PurchaseOrderProgressStatusExtended | undefined): number => {
            const lines = PO.lines

            const getFilteredLines = (statusKey: ApprovalStatusType, approvalStatus: PurchaseOrderApprovalStatus) =>
                lines.filter((line) => line[statusKey] === approvalStatus).length

            const getFilteredFulfillmentLines = (
                fulfillmentKey: FulfillmentStatusType,
                minFulfillmentStatus: FulfillmentStatus
            ) =>
                lines.filter(
                    (line) =>
                        FulfillmentStatusOrder.indexOf(line[fulfillmentKey]) >=
                        FulfillmentStatusOrder.indexOf(minFulfillmentStatus)
                ).length

            if (progress === PurchaseOrderExtraProgressStatus.INVOICED && purchaseOrderRelations.length) {
                return lines.length
            }

            switch (progress) {
                case PurchaseOrderProgressStatus.SUBMITTED:
                    return lines.length

                case PurchaseOrderProgressStatus.INTERNALLY_APPROVED:
                    return getFilteredLines(ApprovalStatusType.BUYER_APPROVAL, PurchaseOrderApprovalStatus.APPROVED)

                case PurchaseOrderProgressStatus.SHARED:
                    return PO.status === PurchaseOrderStatus.OPEN
                        ? getFilteredLines(ApprovalStatusType.BUYER_APPROVAL, PurchaseOrderApprovalStatus.APPROVED)
                        : 0

                case PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED:
                    return getFilteredLines(ApprovalStatusType.SUPPLIER_APPROVAL, PurchaseOrderApprovalStatus.APPROVED)

                case PurchaseOrderProgressStatus.IN_PREPARATION:
                case PurchaseOrderProgressStatus.SHIPPED:
                case PurchaseOrderProgressStatus.RECEIVED: {
                    const buyerFulfillmentCount = getFilteredFulfillmentLines(
                        FulfillmentStatusType.BUYER_FULFILLMENT,
                        progress === PurchaseOrderProgressStatus.IN_PREPARATION
                            ? FulfillmentStatus.IN_PREPARATION
                            : progress === PurchaseOrderProgressStatus.SHIPPED
                            ? FulfillmentStatus.OUT_FOR_DELIVERY
                            : FulfillmentStatus.DELIVERED
                    )

                    const supplierFulfillmentCount = getFilteredFulfillmentLines(
                        FulfillmentStatusType.SUPPLIER_FULFILLMENT,
                        progress === PurchaseOrderProgressStatus.IN_PREPARATION
                            ? FulfillmentStatus.IN_PREPARATION
                            : progress === PurchaseOrderProgressStatus.SHIPPED
                            ? FulfillmentStatus.OUT_FOR_DELIVERY
                            : FulfillmentStatus.DELIVERED
                    )

                    return Math.max(buyerFulfillmentCount, supplierFulfillmentCount)
                }
                default:
                    return 0
            }
        },
        [PO.lines, PO.status, purchaseOrderRelations]
    )

    const getMemoizedNumberOfLinesByProgress = useMemo(
        () => (progress: PurchaseOrderProgressStatusExtended | undefined) => {
            return getNumberOfLinesByProgress(progress)
        },
        [PO.lines, PO.status, purchaseOrderRelations]
    )

    let steps: StepsI[]
    if (isBuyerView) {
        steps = [
            {
                key: "submitted",
                name: formatMessage(messages.POSubmitted),
                progress: PurchaseOrderProgressStatus.SUBMITTED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SUBMITTED)
                        ? "done"
                        : "next",
                icon: <Edit3 size={18} />,
            },
            {
                key: "approved",
                name: formatMessage(messages.approved),
                progress: PurchaseOrderProgressStatus.INTERNALLY_APPROVED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                        PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.INTERNALLY_APPROVED) &&
                    areAllLinesApproved
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "POSent",
                name: formatMessage(messages.POShared),
                progress: PurchaseOrderProgressStatus.SHARED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                        PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHARED) ||
                    PO.status === PurchaseOrderStatus.OPEN
                        ? "done"
                        : "next",
                icon: <Send size={18} />,
            },
            {
                key: "mutuallyAccepted",
                name: formatMessage(messages.mutuallyAccepted),
                progress: PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED)
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "inPreparation",
                name: formatMessage(messages.inPreparation),
                progress: PurchaseOrderProgressStatus.IN_PREPARATION,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.IN_PREPARATION)
                        ? "done"
                        : "next",
                icon: <Package size={18} />,
            },
            {
                key: "inDelivery",
                name: formatMessage(messages.inDelivery),
                progress: PurchaseOrderProgressStatus.SHIPPED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHIPPED)
                        ? "done"
                        : "next",
                icon: <Truck size={18} />,
            },
            {
                key: "received",
                name: formatMessage(messages.received),
                progress: PurchaseOrderProgressStatus.RECEIVED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.RECEIVED)
                        ? "done"
                        : "next",
                icon: <UserCheck size={18} />,
            },
            {
                key: "invoiced",
                name: formatMessage(messages.invoiced),
                progress: PurchaseOrderExtraProgressStatus.INVOICED,
                status: purchaseOrderRelations?.length ? "done" : "next",
                icon: <FileText size={18} />,
            },
        ]
        if (isDraft) {
            steps.unshift({
                key: "draft",
                name: formatMessage(messages.PODraft),
                status: "done",
                icon: <Edit size={18} />,
            })
        }
    } else {
        steps = [
            {
                key: "received",
                name: formatMessage(messages.received),
                progress: PurchaseOrderProgressStatus.SHARED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHARED)
                        ? "done"
                        : "next",
                icon: <Send size={18} />,
            },
            {
                key: "approved",
                name: formatMessage(messages.mutuallyAccepted),
                progress: PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED)
                        ? "done"
                        : "next",
                icon: <Check size={18} />,
            },
            {
                key: "inPreparation",
                name: formatMessage(messages.inPreparation),
                progress: PurchaseOrderProgressStatus.IN_PREPARATION,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.IN_PREPARATION)
                        ? "done"
                        : "next",
                icon: <Package size={18} />,
            },
            {
                key: "inDelivery",
                name: formatMessage(messages.inDelivery),
                progress: PurchaseOrderProgressStatus.SHIPPED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHIPPED)
                        ? "done"
                        : "next",
                icon: <Truck size={18} />,
            },
            {
                key: "delivered",
                name: formatMessage(messages.delivered),
                progress: PurchaseOrderProgressStatus.RECEIVED,
                status:
                    PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                    PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.RECEIVED)
                        ? "done"
                        : "next",
                icon: <UserCheck size={18} />,
            },
            {
                key: "invoiced",
                name: formatMessage(messages.invoiced),
                progress: PurchaseOrderExtraProgressStatus.INVOICED,
                status: purchaseOrderRelations?.length ? "done" : "next",
                icon: <FileText size={18} />,
            },
        ]
    }

    const renderLine = ({ status, icon, name, progress }: StepsI, key: number): React.ReactNode => (
        <li key={key} className={status} {...(isMediumDraftOrContent ? { style: listItemStyles } : {})}>
            <span
                className="steps_data"
                style={{
                    flexDirection: isLargeSizedDraft || (!isDraft && isMediumContent) ? "column" : "row",
                    ...(isMediumSizedDraft ? stepDataStyles : {}),
                }}
            >
                <Tooltip title={name} arrow>
                    <div className="steps_icon">{icon}</div>
                </Tooltip>

                <div className="steps_info">
                    <span className="steps_label" {...(isMediumDraftOrContent ? { style: labelStyles } : {})}>
                        {name}
                    </span>

                    {!isDraft && (
                        <span
                            className="steps_items"
                            style={{
                                display: isSmallContent ? "none" : "block",
                                textAlign: isMediumDraftOrContent ? "center" : "left",
                            }}
                        >
                            {formatMessage(messages.items, {
                                current: getMemoizedNumberOfLinesByProgress(progress),
                                total: PO.lines.length,
                            })}
                        </span>
                    )}
                </div>
            </span>
            <span className="steps_separator" {...(isMediumSizedDraft ? { style: separatorStyles } : {})}>
                <ChevronRight color="var(--color-grey)" size={isMediumDraftOrContent ? 14 : 24} />
            </span>
        </li>
    )

    return (
        <Grid item className="stepper-container">
            <ul className={classes} ref={listContainerRef}>
                {steps.map((step, key) => renderLine(step, key))}
            </ul>
        </Grid>
    )
}
