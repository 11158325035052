import React, { useState } from "react"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { DataTable } from "~/components/DataTable"
import "./Items.scss"
import { taxRateFromBack } from "~/domains/transactions/purchase-requests/utils/lines"
import { AmountInList } from "~/domains/transactions/components/AmountInList"
import {
    PurchaseRequestLine,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { Plus } from "react-feather"
import { Tooltip } from "@mui/material"
import { ActionsPR } from "~/domains/transactions/components/Items/ActionsPR"

import { PurchaseType, PurchaseViewType } from "~/domains/transactions/types/Purchases"

import { ModalAddItems } from "~/domains/transactions/components/Items/ModalAddItems/ModalAddItems"
import { LineTags } from "./LineTags"
import { OrganizationId, CurrencyCodes, SpiceDBPermissionsResult } from "~/types"
import { TagObjectType } from "~/domains/tags/types"

const messages = defineMessages({
    items: {
        id: "purchase.requests.request.items",
        defaultMessage: "Items",
    },
    editItem: {
        id: "purchase.requests.request.items.edit",
        defaultMessage: "Edit items",
    },
    addItem: {
        id: "purchase.requests.request.items.addItem",
        defaultMessage: "Add new items",
    },
    description: {
        id: "purchase.requests.request.items.description",
        defaultMessage: "Description",
    },
    quantity: {
        id: "purchase.requests.request.items.quantity",
        defaultMessage: "Qty",
    },
    taxRate: {
        id: "purchase.requests.request.items.taxRate",
        defaultMessage: "Tax",
    },
    priceHT: {
        id: "purchase.requests.request.items.priceHT",
        defaultMessage: "Price (tax excl)",
    },
    priceTTC: {
        id: "purchase.requests.request.items.priceTTC",
        defaultMessage: "Price (tax incl)",
    },
    actions: {
        id: "purchase.requests.request.items.actions",
        defaultMessage: "Actions",
    },
    total: {
        id: "purchase.requests.request.items.total",
        defaultMessage: "Total",
    },
})

interface Props {
    id: string
    organizationId: OrganizationId
    lines: PurchaseRequestLine[]
    totalAmount: number
    totalAmountExcludingTax: number
    currency: CurrencyCodes
    status: PurchaseRequestStatus
    mode: PurchaseViewType
    withoutTitle?: boolean
    showActions?: boolean
    permissions?: SpiceDBPermissionsResult
}

export function ItemsPR({
    id,
    organizationId,
    lines,
    totalAmount,
    totalAmountExcludingTax,
    currency,
    status,
    mode,
    withoutTitle,
    permissions,
    showActions = true,
}: Props) {
    const { formatMessage } = useIntl()

    const [modalAddItemVisible, setModalAddItemVisible] = useState<boolean>(false)
    const [addNewItem, setAddNewItem] = useState<boolean>(false)
    const handleDisplayModalItems = (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>,
        addNewItem: boolean
    ) => {
        event.preventDefault()
        setModalAddItemVisible(true)
        setAddNewItem(addNewItem)
    }

    const handleCloseModalAddItems = () => {
        setModalAddItemVisible(false)
    }

    const displayActions =
        showActions &&
        !(
            mode !== PurchaseViewType.VIEW ||
            status === PurchaseRequestStatus.REJECTED ||
            status === PurchaseRequestStatus.APPROVED
        )

    return (
        <div className={"items block-container " + (mode === PurchaseViewType.EDIT ? "editable" : "")}>
            {!withoutTitle && (
                <h4>
                    {formatMessage(messages.items)}
                    {mode === PurchaseViewType.EDIT ? (
                        <a href="#" onClick={(event) => handleDisplayModalItems(event, false)}>
                            <FormattedMessage {...messages.editItem} /> +
                        </a>
                    ) : null}
                </h4>
            )}
            {lines.length > 0 ? (
                <DataTable
                    classNames="purchase-requests-table"
                    hidePagination={true}
                    data={lines}
                    withAggregatedFooter
                    columns={[
                        {
                            id: "description",
                            key: "description",
                            label: formatMessage(messages.description),
                            sorter: true,
                            renderCell: (line) => (
                                <Tooltip title={line.description}>
                                    <span className={"truncate-text item-description"}>{line.description}</span>
                                </Tooltip>
                            ),
                            aggregationFunction: () => formatMessage(messages.total),
                        },
                        {
                            id: "quantity",
                            key: "quantity",
                            label: formatMessage(messages.quantity),
                            sorter: true,
                        },
                        {
                            id: "taxRate",
                            key: "taxRate",
                            label: formatMessage(messages.taxRate),
                            sorter: true,
                            renderCell: (line) => taxRateFromBack(line.taxRate) + "%",
                        },
                        {
                            id: "totalAmountExcludingTax",
                            key: "totalAmountExcludingTax",
                            label: formatMessage(messages.priceHT),
                            sorter: true,
                            renderCell: (line) => (
                                <AmountInList amount={line.totalAmountExcludingTax} currency={currency} />
                            ),
                            aggregationFunction: () => (
                                <AmountInList amount={totalAmountExcludingTax} currency={currency} />
                            ),
                        },
                        {
                            id: "totalAmount",
                            key: "totalAmount",
                            label: formatMessage(messages.priceTTC),
                            sorter: true,
                            renderCell: (line) => <AmountInList amount={line.totalAmount} currency={currency} />,
                            aggregationFunction: () => <AmountInList amount={totalAmount} currency={currency} />,
                        },
                        {
                            id: "tags",
                            label: "tags",
                            getValue: () => null,
                            renderCell: (line) => (
                                <LineTags
                                    organizationId={organizationId}
                                    line={line}
                                    objectId={`${line.id ?? line.temporaryId}`}
                                    objectType={TagObjectType.PURCHASE_REQUEST_LINE}
                                />
                            ),
                        },
                        {
                            id: "actions",
                            style: { width: "60px" },
                            visible: displayActions,
                            getValue: () => "",
                            label: (
                                <>
                                    <div className="column-60">{formatMessage(messages.actions)}</div>
                                </>
                            ),
                            renderCell: (line) => (
                                <ActionsPR
                                    organizationId={organizationId}
                                    purchaseRequestId={id}
                                    lineId={line.id}
                                    status={line.approvalStatus}
                                    permissions={permissions}
                                />
                            ),
                        },
                    ]}
                />
            ) : null}
            {mode === PurchaseViewType.EDIT ? (
                <div className="add-items-container">
                    <div onClick={(event) => handleDisplayModalItems(event, true)}>
                        <div className={"add-items-icon"}>
                            <Plus size={34} />
                        </div>
                        <p>{formatMessage(messages.addItem)} +</p>
                    </div>
                </div>
            ) : null}

            <ModalAddItems
                organizationId={organizationId}
                lines={lines}
                currency={currency}
                addNewItem={addNewItem}
                open={modalAddItemVisible}
                close={handleCloseModalAddItems}
                purchaseType={PurchaseType.REQUEST}
            />
        </div>
    )
}
