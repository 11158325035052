import { AttachmentI, CreateInvoiceResponseI, InvoiceUserType, NewOcrInvoiceI, OrganizationId } from "~/types"
import axiosClient from "~/api/axiosClient"
import { parseCreateInvoiceResponse } from "~/types/InvoiceParsers"
import { AttachmentStatus, OcrDocumentId, ReadStatus } from "~/features/ocr/types"
import { PaginationQuery } from "~/utils"
import {
    AttachementI,
    AttachementId,
    PaginatedReceivedEmailsI,
    parseAttachment,
    parsePaginatedReceivedEmails,
} from "~/features/ocr/types/ReceivedEmail"
import { FetchError, FetchErrorType } from "~/domains/common/apiClient/errors"
import { Result, ResultError, getResultErrorValue, isResultError } from "~/core/Result"
import { ContractResultI } from "~/domains/identity/features/documents/types/ContractResult"

const OCR_BASE_URL = import.meta.env.VITE_API_OCR_URL

export interface UploadOCRResultI {
    invoice?: CreateInvoiceResponseI
    rawDocument?: unknown
    images?: string[]
}

export interface NewUploadOCRResultI {
    id: OcrDocumentId
}

export const ocrApi = {
    getAttachmentLink(fileId: string) {
        return `${OCR_BASE_URL}resources/attachment/${fileId}`
    },
    async createInvoiceFromOcrData(
        initiator: InvoiceUserType,
        ocrInvoice: NewOcrInvoiceI
    ): Promise<CreateInvoiceResponseI> {
        const response: Record<string | number | symbol, any> = await axiosClient.post(
            `${OCR_BASE_URL}invoice/create-from-ocr?initiator=${initiator}`,
            ocrInvoice
        )
        return parseCreateInvoiceResponse({
            ...response,
            invoice: {
                ...response?.invoice,
                id: ocrInvoice.id ?? "",
            },
        })
    },
    async newUpload(
        file: File,
        initiator: InvoiceUserType,
        organizationId?: OrganizationId
    ): Promise<NewUploadOCRResultI> {
        const formData = new FormData()
        formData.append("file", file)
        const response: NewUploadOCRResultI = await axiosClient.post(
            `${OCR_BASE_URL}upload-invoice?initiator=${initiator}${
                organizationId ? `&organizationId=${organizationId}` : ""
            }`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }
        )
        return response
    },
    async uploadContract(fileUrl: string): Promise<ContractResultI> {
        const response = await axiosClient.post<ContractResultI>(`${OCR_BASE_URL}upload-contract`, { fileUrl })
        return response as unknown as ContractResultI
    },

    async fetchOrganizationEmails(
        organizationId: OrganizationId,
        query?: PaginationQuery,
        read?: ReadStatus | undefined,
        attachments?: AttachmentStatus | undefined
    ): Promise<Result<PaginatedReceivedEmailsI, FetchError<PaginatedReceivedEmailsI>>> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}${query || read || attachments ? "?" : ""}${
            query ? `${new URLSearchParams(query as Record<string, string>).toString()}&` : ""
        }${read ? `read=${read}&` : ""}${attachments ? `attachments=${attachments}&` : ""}`

        try {
            const response = await axiosClient.get(url)
            const result = parsePaginatedReceivedEmails(response)
            if (isResultError(result)) {
                return ResultError({
                    type: FetchErrorType.PARSING_ERROR,
                    partialResults: [],
                    errors: [getResultErrorValue(result)],
                })
            }
            return result
        } catch {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `An unexpected error occurred`,
            })
        }
    },

    async relaunchAttachmentProcessing(
        organizationId: OrganizationId,
        attachmentId: AttachementId
    ): Promise<Result<AttachementI, FetchError<AttachmentI>>> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}/attachment/${attachmentId}/relaunch`
        try {
            const response = await axiosClient.post(url)
            const result = parseAttachment(response)

            if (isResultError(result)) {
                return ResultError({
                    type: FetchErrorType.PARSING_ERROR,
                    partialResults: [],
                    errors: [getResultErrorValue(result)],
                })
            }
            return result
        } catch (error) {
            return ResultError({
                type: FetchErrorType.HTTP_REQUEST_ERROR,
                code: 500,
                error: `${error}`,
            })
        }
    },

    async markReceivedEmailAsRead(organizationId: OrganizationId, emailId: string): Promise<boolean> {
        const url = `${OCR_BASE_URL}inbox/organization/${organizationId}/emails/${emailId}/read`
        return await axiosClient.post(url)
    },
}
