import { createTheme } from "@mui/material"

export const Theme = createTheme({
    palette: {
        primary: {
            main: "#7B61FF",
            light: "#F1EEFF",
            lighter: "#F1EEFF",
            dark: "#DFD9FF",
            darker: "#DFD9FF",
        },
        secondary: {
            main: "#303133",
            light: "#F0F9EB",
            lighter: "#F0F9EB",
            dark: "#E1F3D8",
            darker: "#E1F3D8",
        },
        success: {
            main: "#67C23A",
            light: "#F0F9EB",
            lighter: "#F0F9EB",
            dark: "#E1F3D8",
            darker: "#E1F3D8",
        },
        warning: {
            main: "#E6A23C",
            light: "#FDE2E2",
            lighter: "#FEF0F0",
            dark: "#FAECD8",
            darker: "#FAECD8",
        },
        error: {
            main: "#F56C6C",
            light: "#FEF0F0",
            lighter: "#FEF0F0",
            dark: "#FDE2E2",
            darker: "#FDE2E2",
        },
        grey: {
            600: "#606266",
            500: "#555555",
            900: "#909399",
            A700: "#DCDFE6",
        },
    },
    typography: {
        fontFamily: ["Noto Sans"].join(","),
        body1: {
            fontSize: 14,
            fontWeight: 500,
        },
        h2: {
            fontWeight: 700,
            fontSize: 22,
            lineHeight: "18px",
            color: "var(--color-secondary)",
        },
        button: {
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1.12,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    minHeight: "var(--header-height)",
                    boxShadow: "none",
                    paddingRight: "0 !important",
                    background: "var(--header-color)",
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: "32px !important",
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: "var(--color-grey)",
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    "&:last-child": {
                        paddingBottom: 0,
                    },
                },
            },
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h2: {
                    fontWeight: 700,
                    fontSize: "var(--h2-font-size)",
                    marginBottom: 0,
                    "&.MuiTypography-gutterBottom": {
                        marginBottom: "16px",
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    background: "var(--color-silver)",
                },
            },
        },

        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: "10px",
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: "var(--color-white)",
                    "&:before": {
                        border: "none",
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    backgroundColor: "var(--color-white)",
                    "&:focus": {
                        backgroundColor: "var(--color-white)",
                    },
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                },
            },
        },
        /*         MuiTabs: {
                    styleOverrides: {
                        root: {
                            minHeight: "40px",
                            // overflow: "inherit",
                        },
                        scroller: {
                            overflow: "hidden !important",
                        },
                        indicator: {
                            backgroundColor: "var(--background-color)",
                            bottom: "-2px",
                        },
                    },
                }, */
        MuiTab: {
            styleOverrides: {
                root: {
                    paddingRight: "12px",
                    paddingLeft: "12px",
                    paddingBottom: "8px",
                    color: "var(--color-light-grey)",
                    background: "transparent",
                    "&:hover, &.Mui-selected": {
                        color: "var(--primary-color)",
                    },
                },
            },
        },
    },
})

export default Theme
