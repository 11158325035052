import React from "react"
import classnames from "classnames"
import { ChevronRight } from "react-feather"
import {
    PurchaseRequestDetails,
    PurchaseRequestStatus,
} from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { defineMessages, useIntl } from "react-intl"
import { Grid } from "@mui/material"

import "~/components/Steps/Steps.scss"

export interface StepsI {
    key: string
    name: string
    value?: string
    done: boolean
    rejected?: boolean
}

const messages = defineMessages({
    draft: {
        id: "purchase.requests.request.steps.draft",
        defaultMessage: "Draft",
    },
    sent: {
        id: "purchase.requests.request.steps.sent",
        defaultMessage: "Sent",
    },
    create: {
        id: "purchase.requests.request.steps.create",
        defaultMessage: "Create",
    },
    done: {
        id: "purchase.requests.request.steps.done",
        defaultMessage: "Done",
    },
    approval: {
        id: "purchase.requests.request.steps.approval",
        defaultMessage: "Approval",
    },
    APPROVED: {
        id: "purchase.requests.request.steps.approved",
        defaultMessage: "Approved",
    },
    REJECTED: {
        id: "purchase.requests.request.steps.rejected",
        defaultMessage: "Rejected",
    },
    CONVERTED: {
        id: "purchase.requests.request.steps.converted",
        defaultMessage: "Done",
    },
})

interface StepsProps {
    PR: PurchaseRequestDetails
    disabled?: boolean
}

export function StepsPurchaseRequest({ PR, disabled }: StepsProps) {
    const { formatMessage, formatDate } = useIntl()

    const classes = classnames("steps", {
        ["disabled"]: disabled,
        ["rejected"]: PR.status === PurchaseRequestStatus.REJECTED,
        ["converted"]: !!PR.purchaseOrder,
    })

    const steps: StepsI[] = [
        {
            key: "draft",
            name: formatMessage(messages.draft),
            value: PR.status === PurchaseRequestStatus.DRAFT ? "Create" : "Done",
            done: true,
        },
        {
            key: "sent",
            name: formatMessage(messages.sent),
            value: PR.submittedDate ? formatDate(PR.submittedDate) : "-",
            done: PR.status !== PurchaseRequestStatus.DRAFT,
        },
        {
            key: "validation",
            name: formatMessage(messages.approval),
            value: PR.purchaseOrder
                ? formatMessage(messages.CONVERTED)
                : PR.status === PurchaseRequestStatus.APPROVED || PR.status === PurchaseRequestStatus.REJECTED
                ? formatMessage(messages[PR.status])
                : "-",
            done: PR.status === PurchaseRequestStatus.APPROVED || PR.status === PurchaseRequestStatus.REJECTED,
            rejected: PR.status === PurchaseRequestStatus.REJECTED,
        },
    ]

    return (
        <Grid item className={"stepper-container"}>
            <ul className={classes}>
                {steps.map((step, key) => (
                    <li key={key} className={step.rejected ? "pending" : step.done ? "done" : ""}>
                        <span className="steps_name">{step.name}</span>
                        {!step.value || disabled ? null : <span className="steps_value">{step.value}</span>}
                        <span className="steps_separator">
                            <ChevronRight color="var(--color-grey)" />
                        </span>
                    </li>
                ))}
            </ul>
        </Grid>
    )
}
