import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import "./Documents.scss"
import { OrganizationId } from "~/types"
import { defineMessages, FormattedMessage } from "react-intl"
import { Box, Grid } from "@mui/material"
import { Button, Loader } from "~/components"
import { UploadDocumentModal } from "../UploadDocument"
import { DocumentsList } from "./DocumentsList"
import { useFetchObjectDocuments } from "../hooks/useFetchObjectDocuments"
import { commonMessages } from "~/common-messages"
import { ErrorMessage } from "~/components/ErrorMessage"
import { DocumentDataI, DraftDocumentI } from "../core/Documents"
import { DocumentObjectType } from "~/domains/identity/features/documents/types"

const messages = defineMessages({
    title: {
        id: "documents.files.title",
        defaultMessage: "Documents",
    },
    addButton: {
        id: "documents.files.addButton",
        defaultMessage: "Add document +",
    },
    fileName: {
        id: "documents.files.fileName",
        defaultMessage: "Name",
    },
})

type DocumentsProps = {
    objectId?: string
    organizationId: OrganizationId
    objectType: DocumentObjectType
    readonly?: boolean
} & (
    | {
          draftDocuments?: undefined
          setDraftDocuments?: undefined
          objectId: string
      }
    | {
          draftDocuments: DraftDocumentI[]
          setDraftDocuments: Dispatch<SetStateAction<DraftDocumentI[]>>
          objectId?: undefined
      }
)

export const Documents: React.FC<DocumentsProps> = ({
    objectId,
    organizationId,
    objectType,
    draftDocuments,
    setDraftDocuments,
    readonly = false,
}) => {
    const [showUploadDocumentModal, setShowUploadDocumentModal] = useState<boolean>(false)
    const [displayableDocuments, setDisplayableDocuments] = useState<DocumentDataI[] | DraftDocumentI[]>([])
    const { documents, loading: documentsLoading, loadingUpload, error } = useFetchObjectDocuments(objectId ?? "")

    useEffect(() => {
        if (objectId) {
            setDisplayableDocuments(documents)
        } else if (draftDocuments) {
            setDisplayableDocuments(draftDocuments)
        }
    }, [draftDocuments, documents, objectId, documentsLoading, error])

    const displayUploadDocumentModal = useCallback(() => {
        setShowUploadDocumentModal(true)
    }, [])

    const hideUploadDocumentModal = useCallback(() => {
        setShowUploadDocumentModal(false)
    }, [])

    const handleAddDocument = () => {
        displayUploadDocumentModal()
    }

    return (
        <Box className="documents">
            <Grid container className="documents-header">
                <h4>
                    <FormattedMessage {...messages.title} />
                </h4>
                {!error && !readonly && (
                    <div className="documents-add-button">
                        <Button type="title" onClick={handleAddDocument}>
                            <FormattedMessage {...messages.addButton} />
                        </Button>
                    </div>
                )}
            </Grid>
            {error ? (
                <ErrorMessage>
                    <div>
                        <FormattedMessage {...commonMessages.error} />
                    </div>
                </ErrorMessage>
            ) : (
                <>
                    {loadingUpload && draftDocuments && draftDocuments.length > 0 && <Loader fullscreen />}
                    {documentsLoading ? (
                        <Loader small />
                    ) : (
                        <DocumentsList documents={displayableDocuments} setDraftDocuments={setDraftDocuments} />
                    )}
                    <UploadDocumentModal
                        organizationId={organizationId}
                        objectId={objectId}
                        objectType={objectType}
                        open={showUploadDocumentModal}
                        close={hideUploadDocumentModal}
                        setDraftDocuments={setDraftDocuments}
                    />
                </>
            )}
        </Box>
    )
}
