import { OrganizationId } from "~/types"
import { isDefined } from "~/utils/isDefined"

export enum Features {
    ChatBot,
    ChatRooms,
    InvoiceCustomFields,
    Documents,
    VeoliaCustomFields,
    Flows,
}

/* To enable Sidebar Feature Flag, uncomment the related sections in the index.ts file of the Sidebar component */

export const organizationsFeatureFlag: Record<OrganizationId, boolean> = {
    "64ec6c29dd74306a85f11c19": true, // Flowie staging
    "64872730349426738cb80712": true, // Flowie production
    "64f89067e53d5f4b94626027": true, // Estelle staging
    "64eca7e3dd74306a85f11cbd": true, // Thomas staging
    "64ec7758dd74306a85f11cb5": true, // Maxence staging
    "64edd35bdd74306a85f11cd3": true, // Vivien staging
    "64f6e968e53d5f4b94626008": true, // Arthur staging
}

export const griffinFeatureFlag: Record<OrganizationId, boolean> = {
    "66095bf2118c1f5059bf4cfa": true, // Griffin staging
    "65f9d1662be4eb771ea731ca": true, // Griffin production
}

export const veoliaFeatureFlag: Record<OrganizationId, boolean> = {
    "666b17a3ea11870c7b2d34b1": true, // Veolia staging
    // "TDB": true, // Veolia production
    "64f89067e53d5f4b94626027": true, // Estelle staging // to be removed
    "64ec6c29dd74306a85f11c19": true, // Flowie staging // to be removed
}

export const flowsFeatureFlag: Record<OrganizationId, boolean> = {
    "64ec6c29dd74306a85f11c19": true, // Flowie staging
    "66fd638173291f3d4b04aea9": true, // Pierre staging
}

type FeatureFlagValue = Record<OrganizationId, boolean> | boolean

const featuresFlags: Record<Features, FeatureFlagValue> = {
    [Features.ChatBot]: organizationsFeatureFlag,
    [Features.ChatRooms]: organizationsFeatureFlag,
    [Features.InvoiceCustomFields]: griffinFeatureFlag,
    [Features.Documents]: organizationsFeatureFlag,
    [Features.VeoliaCustomFields]: veoliaFeatureFlag,
    [Features.Flows]: flowsFeatureFlag,
}

export const isFeatureEnabled = (feature: Features, organizationId?: OrganizationId): boolean => {
    const value = featuresFlags[feature]
    return value === true || (typeof value === "object" && isDefined(organizationId) && value[organizationId] === true)
}
