import React from "react"
import { defineMessages, useIntl } from "react-intl"
import { Play } from "react-feather"
import { useTitle } from "~/core"
import { useCurrentOrganization } from "~/store/organization/hooks"
import { useAppSelector } from "~/store/hooks"
import { selectUser } from "~/store/account/accountSlice"
import { HeaderH1 } from "~/components/Header/HeaderH1"

const messages = defineMessages({
    title: {
        id: "flows.title",
        defaultMessage: "Flows (beta)",
    },
    noOrganization: {
        id: "flows.noOrganization",
        defaultMessage: "No organization",
    },
})

export const FlowsList: React.FC = () => {
    const { formatMessage } = useIntl()

    const pageName = formatMessage(messages.title)
    const user = useAppSelector(selectUser)
    const currentOrganization = useCurrentOrganization(user.organizations)

    useTitle(pageName)

    return (
        <>
            <HeaderH1 title={pageName} icon={<Play />} />
            <div>
                {currentOrganization ? (
                    <>
                        <div>Filters</div>
                        <div>Flows List</div>
                    </>
                ) : (
                    <div>{formatMessage(messages.noOrganization)}</div>
                )}
            </div>
        </>
    )
}
