import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { FetchError } from "~/domains/common/apiClient/errors"
import { DocumentsStore } from "./documentsStore"
import { DocumentI, FolderId, PaginatedDocumentsResultI } from "../types"
import { RootState } from "~/store"
import { OrganizationId } from "~/types"

const initialState: DocumentsStore = {
    currentOrganizationId: null,
    loading: false,
    query: "",
    perPage: 24,
    page: 0,
    parentId: null,
    result: null,
    selectedDocument: null,
    loadingSingleDocument: false,
}

const documentsSlice = createSlice({
    name: "documents",
    initialState,
    reducers: {
        fetchDocuments(
            state,
            {
                payload: { organizationId, query, page, perPage, parentId },
            }: PayloadAction<{
                organizationId: OrganizationId
                query: string
                page: number
                perPage: number
                parentId: FolderId | null
            }>
        ) {
            state.currentOrganizationId = organizationId
            state.loading = true
            state.fetchingError = null
            state.query = query
            state.page = page
            state.perPage = perPage
            state.parentId = parentId
        },
        fetchDocumentsSuccess(
            state,
            {
                payload: { organizationId, result, query, perPage, page, parentId },
            }: PayloadAction<{
                organizationId: OrganizationId
                result: PaginatedDocumentsResultI
                query: string
                page: number
                perPage: number
                parentId: FolderId | null
            }>
        ) {
            if (
                state.currentOrganizationId === organizationId &&
                state.query === query &&
                state.page === page &&
                state.perPage === perPage &&
                state.parentId === parentId
            ) {
                state.loading = false
                state.result = result
            }
        },
        fetchDocumentsFailure(
            state,
            {
                payload: { organizationId, error, query, perPage, page, parentId },
            }: PayloadAction<{
                organizationId: OrganizationId
                error: FetchError<PaginatedDocumentsResultI>
                query: string
                page: number
                perPage: number
                parentId: FolderId | null
            }>
        ) {
            if (
                state.currentOrganizationId === organizationId &&
                state.query === query &&
                state.page === page &&
                state.perPage === perPage &&
                state.parentId === parentId
            ) {
                state.loading = false
                state.fetchingError = error
            }
        },
        insertDocument(
            state,
            {
                payload: { organizationId, document },
            }: PayloadAction<{ organizationId: OrganizationId; document: DocumentI }>
        ) {
            if (state.currentOrganizationId === organizationId && state.result?.documents) {
                state.result.documents = [document, ...state.result.documents]
            }
        },
        setQuery(state, { payload }: PayloadAction<string>) {
            state.query = payload
        },
        fetchingSingleDocument(state) {
            state.loadingSingleDocument = true
            state.selectedDocument = null
            state.fetchingSingleDocumentError = null
        },
        setSelectedDocument(state, { payload }: PayloadAction<DocumentI | null>) {
            state.selectedDocument = payload
        },
        fetchingSingleDocumentFailure(state, { payload: { error } }: PayloadAction<{ error: FetchError<DocumentI> }>) {
            state.loadingSingleDocument = false
            state.fetchingSingleDocumentError = error
        },
    },
})

export const documentsActions = documentsSlice.actions
export const documentsReducer = documentsSlice.reducer

export const selectDocumentsState = (state: RootState) => state.documents
export const selectDocumentsQuery = (state: RootState) => state.documents.query
export const selectSelectedDocument = (state: RootState) => ({
    document: state.documents.selectedDocument,
    loading: state.documents.loadingSingleDocument,
    error: state.documents.fetchingSingleDocumentError,
})
