import * as t from "io-ts"
import { CurrencyCodes, InvoiceStatus } from "~/types"
import { Opaque } from "~/utils"

export enum BudgetRecurrenceType {
    ONESHOT = "ONESHOT",
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    QUARTERLY = "QUARTERLY",
    YEARLY = "YEARLY",
}

export enum TransactionType {
    PURCHASE_REQUEST = "PURCHASE_REQUEST",
    PURCHASE_ORDER = "PURCHASE_ORDER",
    INVOICE = "INVOICE",
    PAYMENT = "PAYMENT",
    DEPOSIT = "DEPOSIT",
}

export enum TransactionStatus {
    REQUESTED = "REQUESTED",
    VALIDATED = "VALIDATED",
    SCHEDULED = "SCHEDULED",
    EXECUTED = "EXECUTED",
    CLOSED = "CLOSED",
}

export enum AmountType {
    AVAILABLE = "AVAILABLE",
    ASKED = "ASKED",
    PLANNED = "PLANNED",
    ENGAGED = "ENGAGED",
    SCHEDULED = "SCHEDULED",
    PAID = "PAID",
    OVERBUDGET = "OVERBUDGET",
}

export type BudgetId = Opaque<string, { readonly T: unique symbol }>

export const BudgetTransactionIO = t.intersection([
    t.type({
        id: t.string,
        budgetId: t.string,
        supplierOrgId: t.string,
        buyerOrgId: t.string,
        description: t.string,
        amount: t.number,
        amountRemainingToPay: t.number,
        amountWithoutTaxes: t.number,
        transactionRefId: t.string,
        transactionStatus: t.string,
        transactionType: t.string,
        createdAt: t.string,
        updatedAt: t.string,
    }),
    t.partial({
        dueDate: t.string,
        partialAmount: t.number,
        partialRate: t.number,
    }),
])

export type BudgetTransactionI = t.TypeOf<typeof BudgetTransactionIO>

export const AddTransactionPayloadIO = t.intersection([
    t.type({
        transactionRefId: t.string,
        transactionStatus: t.string,
        transactionType: t.string,
        supplierOrgId: t.string,
        buyerOrgId: t.string,
        description: t.string,
        amount: t.number,
        amountWithoutTaxes: t.number,
        amountRemainingToPay: t.number,
    }),
    t.partial({
        dueDate: t.string,
        partialAmount: t.number,
        partialRate: t.number,
        expectedDeliveryDate: t.string,
    }),
])

export type AddTransactionPayloadI = t.TypeOf<typeof AddTransactionPayloadIO>

const SumAmountIO = t.type({ sumAmount: t.number, sumAmountWithoutTax: t.number })

export const BudgetSimplifiedMetricsIO = t.type({
    available: t.number,
    asked: SumAmountIO,
    engaged: SumAmountIO,
    paid: SumAmountIO,
    planned: SumAmountIO,
    scheduled: SumAmountIO,
})

export type BudgetSimplifiedMetricsI = t.TypeOf<typeof BudgetSimplifiedMetricsIO>

export const CurrencyCodesIO = t.keyof(
    Object.fromEntries(Object.values(CurrencyCodes).map((code) => [code, null])) as Record<CurrencyCodes, null>
)

export const BudgetDataIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        description: t.string,
        organizationId: t.string,
        ownerId: t.string,
        startDate: t.string,
        initialAmount: t.number,
        currentAmount: t.number,
        expectedAmount: t.number,
        currency: CurrencyCodesIO,
        recurrence: t.string,
    }),
    t.partial({
        amountHistory: t.array(
            t.type({
                amount: t.number,
                budgetId: t.string,
                changeType: t.string,
                id: t.string,
                reasonForChange: t.string,
                timestamp: t.string,
                userId: t.string,
            })
        ),
        endDate: t.string,
        internalReference: t.string,
        parentBudgetId: t.string,
        metrics: BudgetSimplifiedMetricsIO,
        subBudgets: t.array(t.string),
        transactions: t.array(BudgetTransactionIO),
    }),
])

export type BudgetDataI = t.TypeOf<typeof BudgetDataIO> & {
    id: BudgetId
    recurrence?: BudgetRecurrenceType
}

const BudgetDataWithMetricsIO = t.type({
    metrics: BudgetSimplifiedMetricsIO,
    transaction: BudgetTransactionIO,
})

export type BudgetDataWithMetricsI = t.TypeOf<typeof BudgetDataWithMetricsIO> & {
    budget: BudgetDataI
}

export const BudgetCreatePayloadIO = t.intersection([
    t.type({
        name: t.string,
        description: t.string,
        organizationId: t.string,
        ownerId: t.string,
        startDate: t.string,
        initialAmount: t.number,
        currency: CurrencyCodesIO,
        userId: t.string,
        recurrence: t.string,
    }),
    t.partial({
        endDate: t.string,
    }),
])

export type BudgetCreatePayloadI = t.TypeOf<typeof BudgetCreatePayloadIO>

export const BudgetUpdatePayloadIO = t.intersection([
    t.type({
        id: t.string,
        userId: t.string,
        organizationId: t.string,
        reasonForChange: t.string,
    }),
    t.partial({
        name: t.string,
        description: t.string,
        currentAmount: t.number,
        ownerId: t.string,
        startDate: t.string,
        currency: CurrencyCodesIO,
        endDate: t.string,
        recurrence: t.string,
    }),
])

export type BudgetUpdatePayloadI = t.TypeOf<typeof BudgetUpdatePayloadIO>

export interface ApiBudgetUpdateResponse {
    id: BudgetId
}

export const BugdetTransactionsIO = t.type({
    amount: t.number,
    id: t.string,
    transactionRefId: t.string,
    transactionStatus: t.string,
    transactionType: t.string,
})

export const BudgetMetricIO = t.type({
    sumAmount: t.number,
    transactions: t.array(BugdetTransactionsIO),
})

export const BudgetMetricsResponseIO = t.type({
    _error: BudgetMetricIO,
    _total_associated: t.number,
    _total_budget: t.number,
    asked: BudgetMetricIO,
    available: t.number,
    engaged: BudgetMetricIO,
    paid: BudgetMetricIO,
    planned: BudgetMetricIO,
    scheduled: BudgetMetricIO,
})

export type CombinedStatus = TransactionStatus | InvoiceStatus

export type BugdetTransactionsI = t.TypeOf<typeof BugdetTransactionsIO> & {
    transactionStatus: TransactionStatus
    transactionType: CombinedStatus
}

export type BudgetMetricI = t.TypeOf<typeof BudgetMetricIO>

export type BudgetMetricsI = t.TypeOf<typeof BudgetMetricsResponseIO> & {
    id: BudgetId
}

export const BUDGET_FILTER_TYPES: Record<TransactionType, string> = {
    [TransactionType.PURCHASE_REQUEST]: "budgetPurchaseRequests",
    [TransactionType.PURCHASE_ORDER]: "budgetPurchaseOrders",
    [TransactionType.INVOICE]: "budgetInvoices",
    [TransactionType.PAYMENT]: "budgetPayments",
    [TransactionType.DEPOSIT]: "budgetDeposits",
}
