import { useCallback, useState } from "react"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { OrganizationId } from "~/types"
import { toast } from "react-toastify"
import { defineMessages, useIntl } from "react-intl"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { purchaseRequestApi } from "~/domains/transactions/purchase-requests/api/purchaseRequestsApi"
import { useTagsApi } from "~/domains/tags/tagsApi"
import { selectUser } from "~/store/account/accountSlice"
import { useSavePurchaseRequestPendingLineTags } from "./useSavePurchaseRequestPendingLineTags"
import { useSavePurchaseRequestPendingTags } from "./useSavePurchaseRequestPendingTags"
import {
    useSavePRPendingCustomFieldObjects,
    useSavePRPendingCustomFieldValues,
} from "~/domains/transactions/custom-fields/hooks"
import {
    selectPendingCustomFieldObjects,
    selectPendingCustomFieldValues,
} from "~/domains/transactions/custom-fields/store"
import { selectPendingLineTags, tagsActions } from "~/domains/tags/store/tagsSlice"
import { isDefined } from "~/utils/isDefined"
import {
    addLineIdToTags,
    hasPendingCustomFieldObjects,
    hasPendingCustomFieldValues,
    hasPendingLineTags,
} from "~/domains/transactions/common/purchases/purchasesUtils"
import * as Sentry from "@sentry/react"

const messages = defineMessages({
    success: {
        id: "purchase.requests.create.success",
        defaultMessage: "Purchase request created",
    },
    error: {
        id: "purchase.requests.create.error",
        defaultMessage: "Something went wrong with creating this Purchase Request. Please contact your administrator",
    },
})

export const useCreatePurchaseRequest = (organizationId: OrganizationId) => {
    const dispatch = useAppDispatch()
    const pendingLineTags = useAppSelector(selectPendingLineTags)
    const pendingCustomFieldValues = useAppSelector(selectPendingCustomFieldValues)
    const pendingCustomFieldObjects = useAppSelector(selectPendingCustomFieldObjects)
    const { formatMessage } = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>()
    const tagsApi = useTagsApi()
    const user = useAppSelector(selectUser)
    const savePurchaseRequestPendingLineTags = useSavePurchaseRequestPendingLineTags(organizationId)
    const savePurchaseRequestPendingTags = useSavePurchaseRequestPendingTags(organizationId)
    const savePurchaseRequestPendingCustomFieldValues = useSavePRPendingCustomFieldValues(organizationId)
    const savePurchaseRequestPendingCustomFieldObjects = useSavePRPendingCustomFieldObjects(organizationId)

    const createPR = useCallback(
        async (newPR: PurchaseRequestDetails) => {
            try {
                setLoading(true)

                const result = await purchaseRequestApi.create(organizationId, newPR)

                await savePurchaseRequestPendingTags(result.id)

                const hasPendingTags = hasPendingLineTags(pendingLineTags, newPR)
                const hasPendingCustomFields = hasPendingCustomFieldValues(pendingCustomFieldValues, newPR)
                const hasPendingCFObjects = hasPendingCustomFieldObjects(pendingCustomFieldObjects)
                if (hasPendingTags || hasPendingCustomFields || hasPendingCFObjects) {
                    try {
                        const purchaseRequest = await purchaseRequestApi.findById(organizationId, result.id)

                        await Promise.allSettled([
                            hasPendingTags && savePurchaseRequestPendingLineTags(newPR, purchaseRequest),
                            hasPendingCustomFields &&
                                savePurchaseRequestPendingCustomFieldValues(newPR, purchaseRequest),
                            hasPendingCFObjects && savePurchaseRequestPendingCustomFieldObjects(purchaseRequest),
                        ])
                        if (hasPendingTags) {
                            const tags = purchaseRequest.lines
                                .flatMap((line, lineIndex) => {
                                    const temporaryId = newPR.lines[lineIndex]?.temporaryId
                                    const tags = temporaryId ? pendingLineTags[temporaryId] : null
                                    return tags && line.id ? addLineIdToTags(tags, line.id) : []
                                })
                                .filter(isDefined)
                            dispatch(tagsActions.setLinesTags(tags))
                            dispatch(tagsActions.resetPendingTags())
                        }
                    } catch (error) {
                        console.error(`Error saving pending entities for Purchase Request ${result.id}:`, error)
                        Sentry.captureException(error, {
                            tags: { organizationId },
                            extra: { result, hasPendingTags, hasPendingCustomFields, hasPendingCFObjects },
                        })
                    }
                }
                setLoading(false)
                toast.success(formatMessage(messages.success))
                return result
            } catch (e) {
                setError(`${e}`)
                setLoading(false)
                toast.error(formatMessage(messages.error))
                console.error(`Error saving new Purchase Request:`, error)
                Sentry.captureException(error, {
                    tags: { organizationId },
                    extra: { newPR },
                })
                throw e
            }
        },
        [
            savePurchaseRequestPendingTags,
            savePurchaseRequestPendingLineTags,
            savePurchaseRequestPendingCustomFieldValues,
            organizationId,
            user,
            pendingLineTags,
            dispatch,
            tagsApi,
        ]
    )

    return {
        createPR,
        error,
        loading,
    }
}
