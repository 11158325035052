import React, { useCallback, useMemo } from "react"
import { PurchaseRequestDetails } from "~/domains/transactions/purchase-requests/types/PurchaseRequests"
import { Dates } from "~/domains/transactions/components/Dates/Dates"
import { RequesterName } from "~/domains/transactions/components/RequesterName/RequesterName"
import { CurrencySelector } from "~/components/CurrencySelector"
import { Dayjs } from "dayjs"
import { purchaseRequestsActions } from "../../store/purchaseRequestsSlice"
import { PurchaseViewType } from "~/domains/transactions/types"
import { CurrencyI, OrganizationId } from "~/types"
import { useAppDispatch } from "~/store/hooks"
import { defineMessages, useIntl } from "react-intl"
import { Features, isFeatureEnabled } from "~/core/featureFlag"
import { PRInfosVeoliaCustomFields } from "../PRInfosCustomFields/PRInfosVeoliaCustomFields"
import { styled } from "@mui/material"

const NameAndCustomFieldsContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: "12px",
})

const messages = defineMessages({
    currency: {
        id: "purchase.requests.modalNewPR.currency",
        defaultMessage: "Currency",
    },
})

interface Props {
    PR: PurchaseRequestDetails
    newPR: boolean | undefined
    updatePR: (showToast?: boolean) => Promise<PurchaseRequestDetails>
    pageMode: PurchaseViewType
    organizationId: OrganizationId
}

export const PurchaseRequestInfos: React.FC<Props> = ({ PR, newPR, updatePR, pageMode, organizationId }) => {
    const { formatMessage } = useIntl()
    const dispatch = useAppDispatch()

    const hasVeoliaCustomFields = useMemo(
        () => isFeatureEnabled(Features.VeoliaCustomFields, organizationId),
        [organizationId]
    )

    const setDeliveryDate = useCallback(
        (date: Dayjs | null) => {
            if (date) {
                dispatch(
                    purchaseRequestsActions.updateData({
                        field: "expectedDeliveryDate",
                        value: date.set("hours", 18).toISOString(),
                    })
                )
                if (pageMode !== PurchaseViewType.EDIT) {
                    dispatch(purchaseRequestsActions.setShouldUpdatePR(true))
                }
            }
        },
        [dispatch, pageMode, updatePR]
    )

    const handleCurrencyChange = useCallback(
        (currency: CurrencyI | null) => {
            if (currency) {
                dispatch(
                    purchaseRequestsActions.updateData({
                        field: "currency",
                        value: currency.code,
                    })
                )
            }
        },
        [dispatch]
    )

    return (
        <>
            <Dates
                creationDate={PR.creationDate}
                expectedDeliveryDate={PR.expectedDeliveryDate}
                pageMode={pageMode}
                setDeliveryDate={setDeliveryDate}
            />
            <NameAndCustomFieldsContainer>
                {!newPR && <RequesterName requesterName={PR.requesterName ?? ""} />}
                {hasVeoliaCustomFields && <PRInfosVeoliaCustomFields purchaseRequestId={PR.id} pageMode={pageMode} />}
            </NameAndCustomFieldsContainer>
            <CurrencySelector
                value={PR.currency}
                label={formatMessage(messages.currency)}
                editMode={pageMode === PurchaseViewType.EDIT}
                onChange={handleCurrencyChange}
            />
        </>
    )
}
