import { useAppDispatch } from "~/store/hooks"
import { useCustomFieldsApi } from "../customFieldsApi"
import { CustomFieldValueI } from "../types/CustomFieldValue"
import { customFieldsActions } from "../store"
import { useCallback } from "react"
import * as Sentry from "@sentry/react"

export const useUpdateCustomFieldObject = (objectId: string, customField: CustomFieldValueI) => {
    const customFieldsApi = useCustomFieldsApi()
    const dispatch = useAppDispatch()

    return useCallback(
        async (customFieldId: string, value: string) => {
            try {
                await customFieldsApi.updateCustomFieldValue(customFieldId, value)
                dispatch(customFieldsActions.updateCustomFieldObject({ objectId, customField, value }))
            } catch (error) {
                console.error(`Error updating custom field object ${customFieldId}:`, error)
                Sentry.captureException(error, {
                    extra: { customFieldId, customField, objectId, value },
                })
            }
        },
        [dispatch, customFieldsApi]
    )
}
