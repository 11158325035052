import React from "react"
import { Route } from "react-router-dom"
import { ProtectedRoutes } from "~/features/auth/ProtectedRoute"
import { Editor } from "./pages/Editor"
import { FlowsList } from "./pages/FlowsList"

import { EDITOR, FLOWS_LIST } from "./routes"

export default {
    routes: [
        <Route element={<ProtectedRoutes />} key="flows_route">
            <Route path={FLOWS_LIST} element={<FlowsList />} />
            <Route path={EDITOR} element={<Editor />} />
        </Route>,
    ],
}
