import * as t from "io-ts"
import { optional } from "~/types/utils"
import { CustomFieldObjectType } from "./CustomFieldObjectType"
import { OrganizationId } from "~/types"
import { CreateCustomFieldPayload } from "./CreateCustomFieldPayload"

export const CustomFieldValueIO = t.intersection([
    t.type({
        id: t.string,
        name: t.string,
        objectId: t.string,
        value: t.string,
    }),
    t.partial({
        quantity: t.number,
        unit: optional(t.string),
        contextId: t.string,
        contextType: t.string,
        organizationId: t.string,
        createdAt: t.string,
        updatedAt: t.string,
    }),
])

export type CustomFieldValueI = t.TypeOf<typeof CustomFieldValueIO>

export const AggregatedCustomFieldValueIO = t.record(t.string, t.array(CustomFieldValueIO))
export type AggregatedCustomFieldValueI = t.TypeOf<typeof AggregatedCustomFieldValueIO>

export type CustomFieldByNameRecord = Record<string, CustomFieldValueI>
export type CreateCustomFieldByNameRecord = Record<string, CreateCustomFieldPayload>


export type CustomFieldByObjectIdRecord = Record<string, CustomFieldByNameRecord>
export type CreateCustomFieldObjectByNameRecord = Record<string, CreateCustomFieldObjectPayload>

export interface CreateCustomFieldObjectPayload {
    name: string
    value: string
    objectId: string
    contextId?: string
    contextType?: CustomFieldObjectType
    organizationId?: OrganizationId
}
