import { Box, styled } from "@mui/material"
import React, { useCallback, useMemo, useState } from "react"
import { PurchaseOrders } from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { InvoiceUserType, OrganizationId, ViewTypeI } from "~/types"
import { useAddRelationToPurchaseOrder, usePurchaseOrderRelations } from "../../hooks"
import { Loader } from "~/components"
import { PurchaseOrderRelationI } from "../../types/PurchaseOrderRelation"
import { PurchaseOrderRelation } from "./PurchaseOrderRelation"
import { InvoicePicker } from "~/domains/transactions/invoices/components/InvoicePicker"
import { FormattedMessage, defineMessages, useIntl } from "react-intl"
import { useFetchErrorMessage } from "~/domains/common/apiClient/errors"
import { ErrorMessage } from "~/components/ErrorMessage"
import "./PurchaseOrderDocumentRelations.scss"
import { CheckFileTypeResult } from "~/components/UploadDocument/UploadDocument"
import { useUploadOCRDocument } from "~/features/ocr/hooks/useUploadDocument"
import { checkOneFileType } from "~/utils/upload"
import { UploadInvoiceBox } from "../UploadInvoiceBox/UploadInvoiceBox"
import { OCR_IMPORT } from "~/features/ocr/routes"
import { generatePath, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const messages = defineMessages({
    title: {
        id: "transactions.purchaseOrder.documentRelations.title",
        defaultMessage: "{count, plural, =0 {Invoice} one {Invoice} other {Invoices}}",
    },
    or: { id: "transactions.purchaseOrder.documentRelations.or", defaultMessage: "Or" },
    errorWrongFileType: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.errorWrongFileType",
        defaultMessage: "Wrong file format: we only accept PDF files.",
    },
    errorUpload: {
        id: "transactions.purchaseOrder.documentRelations.uploadBox.errorUpload",
        defaultMessage: "An error occurred while uploading the document.",
    },
})

interface Props {
    organizationId: OrganizationId
    purchaseOrder: PurchaseOrders
    viewType: ViewTypeI
}

const InvoiceRelationsList = styled("ul")({
    margin: 0,
    listStyleType: "none",
    padding: 0,
})

const ACCEPTED_FILE_TYPES = "application/pdf"

export const PurchaseOrderDocumentRelations: React.FC<Props> = ({ organizationId, purchaseOrder, viewType }) => {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const [uploadingFile, setUploadingFile] = useState<boolean>(false)

    const { loading, error, purchaseOrderRelations } = usePurchaseOrderRelations(organizationId, purchaseOrder.id)
    const addRelationToPurchaseOrder = useAddRelationToPurchaseOrder(organizationId, purchaseOrder.id)
    const errorMessage = useFetchErrorMessage(error)

    const invoiceIds = useMemo(
        () => purchaseOrderRelations.map((relation) => relation.invoiceId),
        [purchaseOrderRelations]
    )

    const renderPurchaseOrderRelation = useCallback(
        (relation: PurchaseOrderRelationI) => {
            return (
                <PurchaseOrderRelation
                    key={relation.relationId}
                    purchaseOrderRelation={relation}
                    organizationId={organizationId}
                    purchaseOrderId={purchaseOrder.id}
                    viewType={viewType}
                />
            )
        },
        [viewType, organizationId, purchaseOrder.id]
    )

    const uploadDocument = useUploadOCRDocument(InvoiceUserType.SUPPLIER)
    const handleFile = useCallback(
        async (file: File) => {
            setUploadingFile(true)
            const documentId = await uploadDocument(file)
            setUploadingFile(false)

            if (documentId) {
                const searchParams = new URLSearchParams({ purchaseOrderId: purchaseOrder.id }).toString()
                navigate(
                    `${generatePath(OCR_IMPORT, {
                        initiator: InvoiceUserType.SUPPLIER.toLowerCase(),
                        documentId,
                    })}?${searchParams}`
                )
            } else {
                toast.error(formatMessage(messages.errorUpload))
            }
        },
        [uploadDocument]
    )

    const checkFileType = useCallback(
        (file: File): CheckFileTypeResult => {
            return checkOneFileType(file, ACCEPTED_FILE_TYPES, formatMessage(messages.errorWrongFileType))
        },
        [formatMessage]
    )

    if (loading) {
        return <Loader small />
    }

    return (
        <Box component={"div"} className={"invoice-relations-link"}>
            <label className={"invoice-relations-link-title"}>
                <FormattedMessage {...messages.title} values={{ count: purchaseOrderRelations.length }} />
            </label>
            {loading ? (
                <Loader small />
            ) : (
                <InvoiceRelationsList className="invoice-relations-list">
                    {purchaseOrderRelations.map(renderPurchaseOrderRelation)}
                </InvoiceRelationsList>
            )}
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <InvoicePicker
                organizationId={organizationId}
                value={null}
                onChange={addRelationToPurchaseOrder}
                viewType={viewType}
                blackListIds={invoiceIds}
            />
            {organizationId === purchaseOrder.supplierId ? (
                <>
                    <div className="invoice-relations-or">
                        <FormattedMessage {...messages.or} />
                    </div>
                    <UploadInvoiceBox
                        handleFile={handleFile}
                        checkFileType={checkFileType}
                        loading={uploadingFile}
                        acceptedFileTypes={ACCEPTED_FILE_TYPES}
                    />
                </>
            ) : null}
        </Box>
    )
}
