import React, { useEffect, useMemo, useState } from "react"
import { Button } from "~/components"
import { styled } from "@mui/material"
import { Trash, AlertTriangle } from "react-feather"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { ModalConfirm } from "~/components/ModalConfirm/ModalConfirm"

const messages = defineMessages({
    label: { id: "purchase.orders.order.delete.deleteOrder", defaultMessage: "Delete this PO" },
    cancel: { id: "purchase.orders.order.delete.cancel", defaultMessage: "Cancel" },
    confirm: { id: "purchase.orders.order.delete.confirm", defaultMessage: "I want to delete this PO" },
    message: {
        id: "purchase.orders.order.delete.confirmMessage",
        defaultMessage: "Are you sure you want to delete this PO? This action is not reversible!",
    },
})

interface Props {
    handleDelete: () => Promise<void>
}

const DeleteLabel = styled("span")`
    color: var(--color-red);
`

export function Delete({ handleDelete }: Props) {
    const { formatMessage } = useIntl()
    const [deleteRequest, setDeleteRequest] = useState<boolean>(false)
    const [displayModal, setDisplayModal] = useState<boolean>(false)

    const icon = useMemo(
        () => (
            <div className="icon-container red">
                <AlertTriangle size={20} />
            </div>
        ),
        []
    )

    useEffect(() => {
        if (deleteRequest) {
            setDisplayModal(false)
            handleDelete()
        }
    }, [deleteRequest])

    const handleClick = (): void => {
        setDisplayModal(true)
    }

    const handleCancel = (): void => {
        setDisplayModal(false)
    }

    const handleConfirm = (): void => {
        setDeleteRequest(true)
    }

    return (
        <>
            <Button className="delete" type="menu-item" buttonType="button" onClick={handleClick}>
                <Trash color="var(--color-red)" size={16} />
                <DeleteLabel>
                    <FormattedMessage {...messages.label} />
                </DeleteLabel>
            </Button>

            <ModalConfirm
                open={displayModal}
                message={formatMessage(messages.message)}
                icon={icon}
                button1={<Button type="neutral" onClick={handleCancel} text={formatMessage(messages.cancel)} />}
                button2={<Button type="error" onClick={handleConfirm} text={formatMessage(messages.confirm)} />}
            />
        </>
    )
}
