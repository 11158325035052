import React from "react"
import { CustomFieldObjectType } from "~/domains/transactions/custom-fields/types/CustomFieldObjectType"
import { PurchaseViewType } from "~/domains/transactions/types"
import { useAppSelector } from "~/store/hooks"
import { selectCurrentOrganizationId } from "~/store/organization/organizationSlice"
import { useIntl } from "react-intl"
import { useFetchCustomFieldsByObjectId } from "~/domains/transactions/custom-fields/hooks"
import { getOrCreateCustomField } from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CustomFieldObjectHelpers"
import {
    recurrenceModeEnumMessages,
    VeoliaCustomFields,
    VeoliaCustomFieldsMessages,
} from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CompanyCustomFieldsConfig"
import { CustomFieldObjectAsTextfield } from "~/domains/transactions/custom-fields/components/CompanyCustomFields/CustomFieldObjectAsTextfield"

interface Props {
    purchaseRequestId: string
    pageMode: PurchaseViewType
}

export const PRInfosVeoliaCustomFields: React.FC<Props> = ({ purchaseRequestId, pageMode }) => {
    const { formatMessage } = useIntl()
    const currentOrganizationId = useAppSelector(selectCurrentOrganizationId)
    const customFields = useFetchCustomFieldsByObjectId(purchaseRequestId)
    const isReadOnly = pageMode === PurchaseViewType.VIEW
    const isTemporaryId = !purchaseRequestId

    const recurringModeCustomField = getOrCreateCustomField(
        customFields,
        VeoliaCustomFields.RECURRING_MODE,
        purchaseRequestId,
        CustomFieldObjectType.PURCHASE_REQUEST,
        currentOrganizationId
    )

    const paymentTermsCustomField = getOrCreateCustomField(
        customFields,
        VeoliaCustomFields.PAYMENT_TERMS_REQUIRED,
        purchaseRequestId,
        CustomFieldObjectType.PURCHASE_REQUEST,
        currentOrganizationId
    )

    const warrantyPeriodCustomField = getOrCreateCustomField(
        customFields,
        VeoliaCustomFields.WARRANTY_PERIOD_REQUIRED,
        purchaseRequestId,
        CustomFieldObjectType.PURCHASE_REQUEST,
        currentOrganizationId
    )

    const labels = {
        [VeoliaCustomFields.RECURRING_MODE]: formatMessage(
            VeoliaCustomFieldsMessages[VeoliaCustomFields.RECURRING_MODE]
        ),
        [VeoliaCustomFields.PAYMENT_TERMS_REQUIRED]: formatMessage(
            VeoliaCustomFieldsMessages[VeoliaCustomFields.PAYMENT_TERMS_REQUIRED]
        ),
        [VeoliaCustomFields.WARRANTY_PERIOD_REQUIRED]: formatMessage(
            VeoliaCustomFieldsMessages[VeoliaCustomFields.WARRANTY_PERIOD_REQUIRED]
        ),
    }

    return (
        <>
            <CustomFieldObjectAsTextfield
                customField={recurringModeCustomField}
                label={labels[VeoliaCustomFields.RECURRING_MODE]}
                isTemporaryId={isTemporaryId}
                enum={recurrenceModeEnumMessages}
                isReadOnly={isReadOnly}
            />
            <CustomFieldObjectAsTextfield
                customField={paymentTermsCustomField}
                label={labels[VeoliaCustomFields.PAYMENT_TERMS_REQUIRED]}
                isTemporaryId={isTemporaryId}
                isReadOnly={isReadOnly}
            />
            <CustomFieldObjectAsTextfield
                customField={warrantyPeriodCustomField}
                label={labels[VeoliaCustomFields.WARRANTY_PERIOD_REQUIRED]}
                isTemporaryId={isTemporaryId}
                isReadOnly={isReadOnly}
            />
        </>
    )
}
