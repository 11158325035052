import React, { useCallback, useMemo } from "react"
import { Grid } from "@mui/material"
import { SharedObjectType } from "~/types/SharedObjects"
import { ShareButton } from "~/components/ShareButton"
import { OrganizationI, ViewTypeI, AuthorizationName } from "~/types"
import {
    PurchaseOrderApprovalStatus,
    PurchaseOrderProgressStatus,
    PurchaseOrders,
    PurchaseOrderStatus,
    PurchaseOrderStatusOrder,
} from "~/domains/transactions/purchase-orders/types/PurchaseOrders"
import { OrganizationSummary } from "~/domains/transactions/components/Organizations/Organizations"
import { Edit2 } from "react-feather"
import { defineMessages, FormattedMessage, useIntl } from "react-intl"
import { Button, ActionsMenu } from "~/components"
import { FulfillmentStatus, PurchaseViewType } from "~/domains/transactions/types/Purchases"
import DownloadPOPdf from "../DownloadPOPdf/DownloadPOPdf"
import { useHasPermissions } from "~/domains/identity/store/hooks/useHasPermissions"
import { DomainName, ScopeName } from "~/domains/identity/features/roles-permissions/types/RolesPermissions"
import { MarkAllInPreparation } from "./MarkAllInPreparation"
import { Delete } from "./Delete"
import { purchaseOrdersApi } from "~/domains/transactions/purchase-orders/api/purchaseOrdersApi"
import { useNavigate, generatePath } from "react-router-dom"
import { PURCHASE_ORDERS_ROUTE } from "~/domains/transactions/purchase-orders/routes"
import {
    useFetchPurchaseOrder,
    useUpdatePurchaseOrderApprovalStatus,
    useUpdatePurchaseOrderFulfillmentStatus,
} from "~/domains/transactions/purchase-orders/store/hooks"
import { allLinesRejected } from "~/domains/transactions/purchase-orders/core/purchaseOrder"

const messages = defineMessages({
    edit: { id: "purchase.orders.actionsHeader.edit", defaultMessage: "Edit" },
    save: { id: "purchase.orders.actionsHeader.save", defaultMessage: "Save" },
    submit: { id: "purchase.orders.actionsHeader.submit", defaultMessage: "Submit" },
    send: {
        id: "purchase.orders.order.action.send",
        defaultMessage: "Send",
    },
    approve: {
        id: "purchase.orders.order.action.approve",
        defaultMessage: "Mark all as approved",
    },
    reject: {
        id: "purchase.orders.order.action.reject",
        defaultMessage: "Mark all as rejected",
    },
    markAllInPreparation: {
        id: "purchase.orders.actionsHeader.markAllInPreparation",
        defaultMessage: "Mark all as in preparation",
    },
    inTransit: {
        id: "purchase.orders.order.action.inTransit",
        defaultMessage: "Mark all as in transit",
    },
    delivered: {
        id: "purchase.orders.order.action.delivered",
        defaultMessage: "Mark all as delivered",
    },
    received: {
        id: "purchase.orders.order.action.received",
        defaultMessage: "Mark all as received",
    },
})

interface PropsPOActions {
    PO: PurchaseOrders
    organization: OrganizationI
    buyerOrganization: OrganizationSummary
    supplierOrganization: OrganizationSummary
    handleMarkAllInPreparation: () => void
    handleEdit: () => void
    handleSave: () => void
    handleSend: () => void
    handleSubmit: () => void
    mode: PurchaseViewType
    viewType: ViewTypeI
    allLinesApproved: boolean
    isOnApprovalSide: boolean
}

export function ActionsHeader({
    PO,
    organization,
    buyerOrganization,
    supplierOrganization,
    handleMarkAllInPreparation,
    handleEdit,
    handleSave,
    handleSend,
    handleSubmit,
    mode,
    viewType,
    allLinesApproved,
    isOnApprovalSide,
}: PropsPOActions) {
    const { formatMessage } = useIntl()
    const navigate = useNavigate()
    const { updatePOApprovalStatus } = useUpdatePurchaseOrderApprovalStatus(PO.id)
    const { updatePOFulfillmentStatus } = useUpdatePurchaseOrderFulfillmentStatus(PO.id)
    const { fetchPurchaseOrder } = useFetchPurchaseOrder(organization.id)
    const isSupplier = viewType === ViewTypeI.supplier
    const isBuyer = viewType === ViewTypeI.buyer

    const areAllLinesRejected = useMemo(() => allLinesRejected(PO.lines, viewType), [PO.lines, viewType])

    const { hasPermission: hasPOEditPermission } = useHasPermissions(
        organization.id ?? "",
        AuthorizationName.UPDATE,
        DomainName.TRANSACTION,
        ScopeName.PURCHASE_ORDERS
    )

    const handleApprovalStatus = useCallback(
        (status: PurchaseOrderApprovalStatus) => async (e: React.MouseEvent) => {
            e.preventDefault()
            const lineIds = PO.lines.map((line) => line.id ?? "")
            await updatePOApprovalStatus(organization.id, lineIds, status)
            await fetchPurchaseOrder(PO.id)
        },
        [PO, organization.id, updatePOApprovalStatus, fetchPurchaseOrder]
    )

    const handleFulfillmentStatus = useCallback(
        (status: FulfillmentStatus) => async (e: React.MouseEvent) => {
            e.preventDefault()
            const lineIds = PO.lines.map((line) => line.id ?? "")
            await updatePOFulfillmentStatus(organization.id, lineIds, status)
            await fetchPurchaseOrder(PO.id)
        },
        [PO, organization.id, updatePOFulfillmentStatus, fetchPurchaseOrder]
    )

    const handleDelete = useCallback(async () => {
        try {
            await purchaseOrdersApi.delete(organization.id, PO.id)
            navigate(generatePath(PURCHASE_ORDERS_ROUTE, { viewType }))
        } catch (e) {
            console.error(e)
        }
    }, [organization.id, PO.id, navigate, viewType])

    const actions = useMemo(
        () => [
            ...(hasPOEditPermission
                ? [{ label: formatMessage(messages.edit), action: handleEdit, icon: <Edit2 size={16} /> }]
                : []),
            {
                render: (
                    <ShareButton
                        objectId={PO.id}
                        objectType={SharedObjectType.PurchaseOrder}
                        organizationId={organization.id}
                        buyerOrganization={buyerOrganization}
                        supplierOrganization={supplierOrganization}
                        showIcon
                    />
                ),
            },
            { render: <DownloadPOPdf purchaseOrder={PO} /> },
            ...(PO.status === PurchaseOrderStatus.DRAFT || PO.status === PurchaseOrderStatus.SUBMITTED
                ? [{ render: <Delete handleDelete={handleDelete} key="delete" /> }]
                : []),
        ],
        [
            PO,
            organization,
            buyerOrganization,
            supplierOrganization,
            hasPOEditPermission,
            formatMessage,
            handleEdit,
            handleDelete,
        ]
    )

    const renderActionButtons = () => {
        if (mode === PurchaseViewType.VIEW) {
            return (
                <>
                    <ActionsMenu isActionIcon={false} actions={actions} buttonType="default" />
                    {PO.progress === PurchaseOrderProgressStatus.DRAFT && (
                        <Button type="default" onClick={handleSubmit}>
                            <span>{formatMessage(messages.submit)}</span>
                        </Button>
                    )}
                    {isOnApprovalSide && !areAllLinesRejected && (
                        <Button type="error-light" onClick={handleApprovalStatus(PurchaseOrderApprovalStatus.REJECTED)}>
                            {formatMessage(messages.reject)}
                        </Button>
                    )}
                    {isOnApprovalSide && !allLinesApproved && (
                        <Button type="default" onClick={handleApprovalStatus(PurchaseOrderApprovalStatus.APPROVED)}>
                            {formatMessage(messages.approve)}
                        </Button>
                    )}
                    {isOnApprovalSide && allLinesApproved && isBuyer && PO.status === PurchaseOrderStatus.SUBMITTED && (
                        <Button type="default" onClick={handleSend}>
                            {formatMessage(messages.send)}
                        </Button>
                    )}
                    {isSupplier && PO.progress === PurchaseOrderProgressStatus.MUTUALLY_ACCEPTED && (
                        <MarkAllInPreparation handleMarkInPreparation={handleMarkAllInPreparation} />
                    )}
                    {isSupplier && PO.progress === PurchaseOrderProgressStatus.IN_PREPARATION && (
                        <Button type="default" onClick={handleFulfillmentStatus(FulfillmentStatus.OUT_FOR_DELIVERY)}>
                            {formatMessage(messages.inTransit)}
                        </Button>
                    )}
                    {(isBuyer &&
                        PurchaseOrderStatusOrder.indexOf(PO.progress) >=
                            PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.SHARED) &&
                        PurchaseOrderStatusOrder.indexOf(PO.progress) <
                            PurchaseOrderStatusOrder.indexOf(PurchaseOrderProgressStatus.RECEIVED)) ||
                    (isSupplier && PO.progress === PurchaseOrderProgressStatus.SHIPPED) ? (
                        <Button type="default" onClick={handleFulfillmentStatus(FulfillmentStatus.DELIVERED)}>
                            {formatMessage(isBuyer ? messages.received : messages.delivered)}
                        </Button>
                    ) : null}
                </>
            )
        }
        return (
            <Button type="default" onClick={handleSave}>
                <FormattedMessage {...messages.save} />
            </Button>
        )
    }

    return (
        <Grid item className="actions">
            {renderActionButtons()}
        </Grid>
    )
}
